var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.menu.children)?_c('li',{staticClass:"has-sub",class:{
    active: _vm.subIsActive(_vm.menu.path),
    expand: this.stat == 'expand',
    'd-none': this.stat == 'hide',
  }},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.expand($event)},"mouseover":function($event){return _vm.mouseOver(_vm.menu.children, $event)},"mouseleave":_vm.hideFloatSubmenu}},[(_vm.menu.badge)?_c('span',{staticClass:"badge pull-right"},[_vm._v(_vm._s(_vm.menu.badge))]):_c('b',{staticClass:"caret"}),(_vm.menu.icon)?_c('i',{class:_vm.menu.icon}):_vm._e(),(_vm.menu.img)?_c('div',{staticClass:"icon-img"},[_c('img',{attrs:{"src":_vm.menu.img,"alt":""}})]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.menu.title)+" "),(_vm.menu.label)?_c('span',{staticClass:"label label-theme"},[_vm._v(_vm._s(_vm.menu.label))]):_vm._e(),(_vm.menu.highlight)?_c('i',{staticClass:"fa fa-paper-plane text-theme"}):_vm._e()])]),_c('ul',{staticClass:"sub-menu",class:{
      'd-block': this.stat == 'expand',
      'd-none': this.stat == 'collapse',
    },style:({
      marginTop: _vm.pageOptions.pageSidebarMinified
        ? -(_vm.scrollTop + 40) + 'px'
        : '',
    })},[_vm._l((_vm.menu.children),function(submenu){return [_c('sidebar-nav-list',{key:submenu.path,ref:"sidebarNavList",refInFor:true,attrs:{"menu":submenu},on:{"calc-float-submenu":_vm.handleCalcFloatSubmenu,"collapse-other":function($event){return _vm.handleCollapseOther(submenu)}}})]})],2)]):_c('router-link',{staticClass:"py-1",class:{ 'd-none': this.stat == 'hide' },attrs:{"to":_vm.menu.path,"active-class":"active","tag":"li"},nativeOn:{"click":function($event){return _vm.collapseOther()}}},[_c('a',[(_vm.menu.badge)?_c('span',{staticClass:"badge pull-right"},[_vm._v(_vm._s(_vm.menu.badge))]):_vm._e(),(_vm.menu.icon)?_c('i',{class:_vm.menu.icon}):_vm._e(),(_vm.menu.img)?_c('div',{staticClass:"icon-img"},[_c('img',{attrs:{"src":_vm.menu.img,"alt":""}})]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.menu.title)+" "),(_vm.menu.highlight)?_c('i',{staticClass:"fa fa-paper-plane text-theme"}):_vm._e(),(_vm.menu.label)?_c('span',{staticClass:"label label-theme"},[_vm._v(_vm._s(_vm.menu.label))]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
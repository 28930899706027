<template>
  <div class="vertical-box">
    <div class="wrapper">
      <div class="btn-toolbar">
        <div class="btn-group btn-block m-r-5">
          <a
            href="javascript:;"
            class="btn btn-default btn-sm p-l-auto p-r-auto"
            style="font-weight: bold"
          >
            {{ nama_form }}
          </a>
        </div>
      </div>
    </div>

    <div class="vertical-box-row bg-white">
      <div class="vertical-box-cell">
        <div class="vertical-box-inner-cell">
          <vue-custom-scrollbar class="height-full">
            <div class="container mt-3">
              <div>
                <div class="row">
                  <Loader v-if="this.loadingData"></Loader>
                  <div v-else class="container">
                    <div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="nama_pemeriksaan"
                          >Nama Pemeriksaan</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.nama_pemeriksaan"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.nama_pemeriksaan,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="nomor_permintaan"
                          >Nomor Permintaan</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.nomor_permintaan"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.nomor_permintaan,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="dokter_pengirim"
                          >Dokter Pengirim</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.dokter_pengirim"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.dokter_pengirim,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="prioritas_pengirim"
                          >Prioritas Pemeriksaan</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.prioritas_pengirim"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.prioritas_pengirim,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="diagnosis_masalah"
                          >Diagnosis / Masalah</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.diagnosis_masalah"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.diagnosis_masalah,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="catatan_permintaan"
                          >Catatan Permintaan</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.catatan_permintaan"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.catatan_permintaan,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="metode_pengiriman_hasil"
                          >Metode Pengiriman Hasil</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.metode_pengiriman_hasil"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid':
                                formValidate.metode_pengiriman_hasil,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="asal_sumber_spesimen"
                          >Asal Sumber Spesimen Klinis</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.asal_sumber_spesimen"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.asal_sumber_spesimen,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="lokasi_pengambilan_spesimen"
                          >Lokasi Pengambilan Spesimen Klinis</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="
                              pemeriksaan_lab.lokasi_pengambilan_spesimen
                            "
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid':
                                formValidate.lokasi_pengambilan_spesimen,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="jumlah_spesimen"
                          >Jumlah Spesimen Klinis</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.jumlah_spesimen"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.jumlah_spesimen,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="volume_spesimen"
                          >Volume Spesimen Klinis</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.volume_spesimen"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.volume_spesimen,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="metode_pengambilan_spesimen"
                          >Cara / Metode Pengambilan Spesimen Klinis</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="
                              pemeriksaan_lab.metode_pengambilan_spesimen
                            "
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid':
                                formValidate.metode_pengambilan_spesimen,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="tgl_pengambilan_spesimen"
                          >Tanggal Pengambilan Spesimen Klinis</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.tgl_pengambilan_spesimen"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid':
                                formValidate.tgl_pengambilan_spesimen,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="jam_pengambilan_spesimen"
                          >Jam Pengambilan Spesimen Klinis</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.jam_pengambilan_spesimen"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid':
                                formValidate.jam_pengambilan_spesimen,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="kondisi_spesimen"
                          >Kondisi Spesimen Klinis pada saat Pengambilan</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.kondisi_spesimen"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.kondisi_spesimen,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="tgl_fiksasi_spesimen"
                          >Tanggal Fiksasi Spesimen Klinis</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.tgl_fiksasi_spesimen"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.tgl_fiksasi_spesimen,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="jam_fiksasi_spesimen"
                          >Jam Fiksasi Spesimen Klinis</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.jam_fiksasi_spesimen"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.jam_fiksasi_spesimen,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="cairan_fiksasi"
                          >Cairan Fiksasi</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.cairan_fiksasi"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.cairan_fiksasi,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="volume_cairan_fiksasi"
                          >Volume Cairan Fiksasi</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="pemeriksaan_lab.volume_cairan_fiksasi"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.volume_cairan_fiksasi,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-custom-scrollbar>
        </div>
      </div>
    </div>

    <div class="wrapper clearfix">
      <button type="button" class="btn btn-default" @click="toggleForm(5)">
        Sebelumnya
      </button>
      <button
        v-if="!this.loadingData"
        type="button"
        class="btn btn-teal pull-right"
        @click="saveData(7)"
      >
        <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
        Selanjutnya
      </button>
    </div>
  </div>
</template>

<script>
import PageOptions from "../../../config/PageOptions.vue";
import axios from "axios";
import sipData from "../../../plugins/sipData";
import Loader from "../../../components/loader/Loader.vue";

export default {
  mixins: [sipData],
  props: ["kode_register", "no_rekam_medik", "nama_form"],
  components: {
    Loader,
  },
  data() {
    PageOptions.pageContentFullWidth = true;
    PageOptions.pageContentFullHeight = true;
    return {
      pemeriksaan_lab: {
        id_pemeriksaan_lab: "",
        kode_pemeriksaan_lab: "",
        kode_register: "",
        kode_rekam_medik: "",
        nama_pemeriksaan: "",
        nomor_permintaan: "",
        tanggal_permintaan: "",
        jam_permintaan: "",
        dokter_pengirim: "",
        telp_dokter_pengirim: "",
        faskes_pengirim_spesimen: "",
        unit_faskes_pengirim_spesimen: "",
        prioritas_pengirim: "",
        diagnosis_masalah: "",
        catatan_permintaan: "",
        metode_pengiriman_hasil: "",
        asal_sumber_spesimen: "",
        lokasi_pengambilan_spesimen: "",
        jumlah_spesimen: "",
        volume_spesimen: "",
        metode_pengambilan_spesimen: "",
        tgl_pengambilan_spesimen: "",
        jam_pengambilan_spesimen: "",
        kondisi_spesimen: "",
        tgl_fiksasi_spesimen: "",
        jam_fiksasi_spesimen: "",
        cairan_fiksasi: "",
        volume_cairan_fiksasi: "",
        petugas_pengambil_spesimen: "",
        petugas_pengantar_spesimen: "",
        petugas_penerimaa_spesimen: "",
        petugas_penganalisis_spesimen: "",
        tgl_pemeriksaan_spesimen: "",
        jam_pemeriksaan_spesimen: "",
        nilai_hasil_pemeriksaan: "",
        nilai_kenormalan: "",
        nilai_rujukan: "",
        nilai_kritis: "",
        interpretasi_hasil: "",
        dokter_validator: "",
        dokter_interpretasi: "",
        tgl_keluar_lab: "",
        jam_keluar_lab: "",
        tgl_diterima_unit: "",
        jam_diterima_unit: "",
        faskes_pemeriksa: "",
      },
      formValidate: [],
      loadingData: false,
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    saveData(param) {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.pemeriksaan_lab) {
        formData.append(key, this.pemeriksaan_lab[key]);
      }
      formData.append("kode_rekam_medik", this.no_rekam_medik);
      formData.append("kode_register", this.kode_register);

      axios
        .post("/v1/rekammedik/rek_pemeriksaan_lab", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.$emit("formCode", param);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      this.loadingData = true;
      axios
        .get("/v1/rekammedik/pemeriksaan_lab/", {
          params: {
            kode_register: this.kode_register,
          },
        })
        .then((response) => {
          for (let key in response.data.data) {
            this.pemeriksaan_lab[key] = response.data.data[key];
          }
          this.loadingData = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    toggleForm(param) {
      this.$emit("formCode", param);
    },
  },
};
</script>
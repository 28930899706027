var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vertical-box"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"btn-toolbar"},[_c('div',{staticClass:"btn-group btn-block m-r-5"},[_c('a',{staticClass:"btn btn-default btn-sm p-l-auto p-r-auto",staticStyle:{"font-weight":"bold"},attrs:{"href":"javascript:;"}},[_vm._v(" "+_vm._s(_vm.nama_form)+" ")])])])]),_c('div',{staticClass:"vertical-box-row bg-white"},[_c('div',{staticClass:"vertical-box-cell"},[_c('div',{staticClass:"vertical-box-inner-cell"},[_c('vue-custom-scrollbar',{staticClass:"height-full"},[_c('div',{staticClass:"container mt-3"},[_c('div',[_c('div',{staticClass:"row"},[(this.loadingData)?_c('Loader'):_c('div',{staticClass:"container"},[_c('div',[_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"nama_pemeriksaan"}},[_vm._v("Nama Pemeriksaan")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.nama_pemeriksaan),expression:"pemeriksaan_lab.nama_pemeriksaan"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.nama_pemeriksaan,
                          },domProps:{"value":(_vm.pemeriksaan_lab.nama_pemeriksaan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "nama_pemeriksaan", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"nomor_permintaan"}},[_vm._v("Nomor Permintaan")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.nomor_permintaan),expression:"pemeriksaan_lab.nomor_permintaan"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.nomor_permintaan,
                          },domProps:{"value":(_vm.pemeriksaan_lab.nomor_permintaan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "nomor_permintaan", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"dokter_pengirim"}},[_vm._v("Dokter Pengirim")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.dokter_pengirim),expression:"pemeriksaan_lab.dokter_pengirim"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.dokter_pengirim,
                          },domProps:{"value":(_vm.pemeriksaan_lab.dokter_pengirim)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "dokter_pengirim", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"prioritas_pengirim"}},[_vm._v("Prioritas Pemeriksaan")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.prioritas_pengirim),expression:"pemeriksaan_lab.prioritas_pengirim"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.prioritas_pengirim,
                          },domProps:{"value":(_vm.pemeriksaan_lab.prioritas_pengirim)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "prioritas_pengirim", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"diagnosis_masalah"}},[_vm._v("Diagnosis / Masalah")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.diagnosis_masalah),expression:"pemeriksaan_lab.diagnosis_masalah"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.diagnosis_masalah,
                          },domProps:{"value":(_vm.pemeriksaan_lab.diagnosis_masalah)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "diagnosis_masalah", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"catatan_permintaan"}},[_vm._v("Catatan Permintaan")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.catatan_permintaan),expression:"pemeriksaan_lab.catatan_permintaan"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.catatan_permintaan,
                          },domProps:{"value":(_vm.pemeriksaan_lab.catatan_permintaan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "catatan_permintaan", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"metode_pengiriman_hasil"}},[_vm._v("Metode Pengiriman Hasil")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.metode_pengiriman_hasil),expression:"pemeriksaan_lab.metode_pengiriman_hasil"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid':
                              _vm.formValidate.metode_pengiriman_hasil,
                          },domProps:{"value":(_vm.pemeriksaan_lab.metode_pengiriman_hasil)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "metode_pengiriman_hasil", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"asal_sumber_spesimen"}},[_vm._v("Asal Sumber Spesimen Klinis")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.asal_sumber_spesimen),expression:"pemeriksaan_lab.asal_sumber_spesimen"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.asal_sumber_spesimen,
                          },domProps:{"value":(_vm.pemeriksaan_lab.asal_sumber_spesimen)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "asal_sumber_spesimen", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"lokasi_pengambilan_spesimen"}},[_vm._v("Lokasi Pengambilan Spesimen Klinis")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.pemeriksaan_lab.lokasi_pengambilan_spesimen
                          ),expression:"\n                            pemeriksaan_lab.lokasi_pengambilan_spesimen\n                          "}],staticClass:"form-control m-b-5",class:{
                            'is-invalid':
                              _vm.formValidate.lokasi_pengambilan_spesimen,
                          },domProps:{"value":(
                            _vm.pemeriksaan_lab.lokasi_pengambilan_spesimen
                          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "lokasi_pengambilan_spesimen", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"jumlah_spesimen"}},[_vm._v("Jumlah Spesimen Klinis")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.jumlah_spesimen),expression:"pemeriksaan_lab.jumlah_spesimen"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.jumlah_spesimen,
                          },domProps:{"value":(_vm.pemeriksaan_lab.jumlah_spesimen)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "jumlah_spesimen", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"volume_spesimen"}},[_vm._v("Volume Spesimen Klinis")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.volume_spesimen),expression:"pemeriksaan_lab.volume_spesimen"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.volume_spesimen,
                          },domProps:{"value":(_vm.pemeriksaan_lab.volume_spesimen)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "volume_spesimen", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"metode_pengambilan_spesimen"}},[_vm._v("Cara / Metode Pengambilan Spesimen Klinis")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.pemeriksaan_lab.metode_pengambilan_spesimen
                          ),expression:"\n                            pemeriksaan_lab.metode_pengambilan_spesimen\n                          "}],staticClass:"form-control m-b-5",class:{
                            'is-invalid':
                              _vm.formValidate.metode_pengambilan_spesimen,
                          },domProps:{"value":(
                            _vm.pemeriksaan_lab.metode_pengambilan_spesimen
                          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "metode_pengambilan_spesimen", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"tgl_pengambilan_spesimen"}},[_vm._v("Tanggal Pengambilan Spesimen Klinis")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.tgl_pengambilan_spesimen),expression:"pemeriksaan_lab.tgl_pengambilan_spesimen"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid':
                              _vm.formValidate.tgl_pengambilan_spesimen,
                          },domProps:{"value":(_vm.pemeriksaan_lab.tgl_pengambilan_spesimen)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "tgl_pengambilan_spesimen", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"jam_pengambilan_spesimen"}},[_vm._v("Jam Pengambilan Spesimen Klinis")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.jam_pengambilan_spesimen),expression:"pemeriksaan_lab.jam_pengambilan_spesimen"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid':
                              _vm.formValidate.jam_pengambilan_spesimen,
                          },domProps:{"value":(_vm.pemeriksaan_lab.jam_pengambilan_spesimen)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "jam_pengambilan_spesimen", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"kondisi_spesimen"}},[_vm._v("Kondisi Spesimen Klinis pada saat Pengambilan")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.kondisi_spesimen),expression:"pemeriksaan_lab.kondisi_spesimen"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.kondisi_spesimen,
                          },domProps:{"value":(_vm.pemeriksaan_lab.kondisi_spesimen)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "kondisi_spesimen", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"tgl_fiksasi_spesimen"}},[_vm._v("Tanggal Fiksasi Spesimen Klinis")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.tgl_fiksasi_spesimen),expression:"pemeriksaan_lab.tgl_fiksasi_spesimen"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.tgl_fiksasi_spesimen,
                          },domProps:{"value":(_vm.pemeriksaan_lab.tgl_fiksasi_spesimen)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "tgl_fiksasi_spesimen", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"jam_fiksasi_spesimen"}},[_vm._v("Jam Fiksasi Spesimen Klinis")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.jam_fiksasi_spesimen),expression:"pemeriksaan_lab.jam_fiksasi_spesimen"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.jam_fiksasi_spesimen,
                          },domProps:{"value":(_vm.pemeriksaan_lab.jam_fiksasi_spesimen)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "jam_fiksasi_spesimen", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"cairan_fiksasi"}},[_vm._v("Cairan Fiksasi")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.cairan_fiksasi),expression:"pemeriksaan_lab.cairan_fiksasi"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.cairan_fiksasi,
                          },domProps:{"value":(_vm.pemeriksaan_lab.cairan_fiksasi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "cairan_fiksasi", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"volume_cairan_fiksasi"}},[_vm._v("Volume Cairan Fiksasi")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_lab.volume_cairan_fiksasi),expression:"pemeriksaan_lab.volume_cairan_fiksasi"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.volume_cairan_fiksasi,
                          },domProps:{"value":(_vm.pemeriksaan_lab.volume_cairan_fiksasi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_lab, "volume_cairan_fiksasi", $event.target.value)}}})])])])])],1)])])])],1)])]),_c('div',{staticClass:"wrapper clearfix"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleForm(5)}}},[_vm._v(" Sebelumnya ")]),(!this.loadingData)?_c('button',{staticClass:"btn btn-teal pull-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveData(7)}}},[(this.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" Selanjutnya ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vertical-box"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"btn-toolbar"},[_c('div',{staticClass:"btn-group btn-block m-r-5"},[_c('a',{staticClass:"btn btn-default btn-sm p-l-auto p-r-auto",staticStyle:{"font-weight":"bold"},attrs:{"href":"javascript:;"}},[_vm._v(" "+_vm._s(_vm.nama_form)+" ")])])])]),_c('div',{staticClass:"vertical-box-row bg-white"},[_c('div',{staticClass:"vertical-box-cell"},[_c('div',{staticClass:"vertical-box-inner-cell"},[_c('vue-custom-scrollbar',{staticClass:"height-full"},[_c('div',{staticClass:"container mt-3"},[_c('div',[_c('div',{staticClass:"row"},[(this.loadingData)?_c('Loader'):_c('div',{staticClass:"container"},[_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"keluhan_utama"}},[_vm._v("Keluhan Utama")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.anamnesis.keluhan_utama),expression:"anamnesis.keluhan_utama"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.keluhan_utama,
                        },domProps:{"value":(_vm.anamnesis.keluhan_utama)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.anamnesis, "keluhan_utama", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"riwayat_penyakit"}},[_vm._v("Riwayat Penyakit")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.anamnesis.riwayat_penyakit),expression:"anamnesis.riwayat_penyakit"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.riwayat_penyakit,
                        },domProps:{"value":(_vm.anamnesis.riwayat_penyakit)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.anamnesis, "riwayat_penyakit", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"riwayat_alergi"}},[_vm._v("Riwayat Alergi")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.anamnesis.riwayat_alergi),expression:"anamnesis.riwayat_alergi"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.riwayat_alergi,
                        },attrs:{"list":"listAlergi"},domProps:{"value":(_vm.anamnesis.riwayat_alergi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.anamnesis, "riwayat_alergi", $event.target.value)}}}),_c('datalist',{attrs:{"id":"listAlergi"}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"Obat"}},[_vm._v("Obat")]),_c('option',{attrs:{"value":"Makanan"}},[_vm._v("Makanan")]),_c('option',{attrs:{"value":"Udara"}},[_vm._v("Udara")])])])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"riwayat_pengobatan"}},[_vm._v("Riwayat Pengobatan")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.anamnesis.riwayat_pengobatan),expression:"anamnesis.riwayat_pengobatan"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.riwayat_pengobatan,
                        },domProps:{"value":(_vm.anamnesis.riwayat_pengobatan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.anamnesis, "riwayat_pengobatan", $event.target.value)}}})])])])],1)])])])],1)])]),_c('div',{staticClass:"wrapper clearfix"},[_c('a',{staticClass:"btn btn-default",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Kembali")]),(!this.loadingData)?_c('button',{staticClass:"btn btn-teal pull-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveData(2)}}},[(this.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" Selanjutnya ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
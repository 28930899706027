<template>
  <div class="vertical-box">
    <div class="wrapper">
      <div class="btn-toolbar">
        <div class="btn-group btn-block m-r-5">
          <a
            href="javascript:;"
            class="btn btn-default btn-sm p-l-auto p-r-auto"
            style="font-weight: bold"
          >
            {{ nama_form }}
          </a>
        </div>
      </div>
    </div>

    <div class="vertical-box-row bg-white">
      <div class="vertical-box-cell">
        <div class="vertical-box-inner-cell">
          <vue-custom-scrollbar class="height-full">
            <div class="container mt-3">
              <div>
                <div class="row">
                  <Loader v-if="this.loadingData"></Loader>
                  <div v-else class="container">
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="status_psikologis"
                        >Diagnosis Awal / Masuk</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="search.diagnosis_awal"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.diagnosis_awal,
                          }"
                          placeholder="Masukkan Diagnosa/Kode ICD 10"
                          @input="handleDiagnosa(1)"
                        />
                      </div>
                    </div>

                    <div class="form-group row m-b-15" v-if="showDiagnosaAwal">
                      <label class="col-form-label col-md-3"></label>
                      <div class="col-md-9">
                        <table style="width: 100%">
                          <tr
                            v-for="icd in rows.icd"
                            v-bind:key="icd.code"
                            @click="setDiagnosa(1, icd.code, icd.name_id)"
                          >
                            <td>
                              <a
                                class="btn btn-sm btn-block btn-default"
                                style="text-align: left"
                                href="javascript:;"
                                >{{ icd.code }} : {{ icd.name_id }}</a
                              >
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="sosial_ekonomi"
                        >Diagnosis Primer
                      </label>
                      <div class="col-md-9">
                        <input
                          v-model="search.diagnosis_primer"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.diagnosis_primer,
                          }"
                          placeholder="Masukkan Diagnosa/Kode ICD 10"
                          @input="handleDiagnosa(2)"
                        />
                      </div>
                    </div>

                    <div
                      class="form-group row m-b-15"
                      v-if="showDiagnosaPrimer"
                    >
                      <label class="col-form-label col-md-3"></label>
                      <div class="col-md-9">
                        <table style="width: 100%">
                          <tr
                            v-for="icd in rows.icd"
                            v-bind:key="icd.code"
                            @click="setDiagnosa(2, icd.code, icd.name_id)"
                          >
                            <td>
                              <a
                                class="btn btn-sm btn-block btn-default"
                                style="text-align: left"
                                href="javascript:;"
                                >{{ icd.code }} : {{ icd.name_id }}</a
                              >
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="spiritual"
                        >Diagnosis Sekunder</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="search.diagnosis_sekunder"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.diagnosis_sekunder,
                          }"
                          placeholder="Masukkan Diagnosa/Kode ICD 10"
                          @input="handleDiagnosa(3)"
                        />
                      </div>
                    </div>

                    <div
                      class="form-group row m-b-15"
                      v-if="showDiagnosaSekunder"
                    >
                      <label class="col-form-label col-md-3"></label>
                      <div class="col-md-9">
                        <table style="width: 100%">
                          <tr
                            v-for="icd in rows.icd"
                            v-bind:key="icd.code"
                            @click="setDiagnosa(3, icd.code, icd.name_id)"
                          >
                            <td>
                              <a
                                class="btn btn-sm btn-block btn-default"
                                style="text-align: left"
                                href="javascript:;"
                                >{{ icd.code }} : {{ icd.name_id }}</a
                              >
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-custom-scrollbar>
        </div>
      </div>
    </div>

    <div class="wrapper clearfix">
      <button type="button" class="btn btn-default" @click="toggleForm(4)">
        Sebelumnya
      </button>
      <button
        v-if="!this.loadingData"
        type="button"
        class="btn btn-teal pull-right"
        @click="saveData(8)"
      >
        <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
        Selanjutnya
      </button>
    </div>
  </div>
</template>

<script>
import PageOptions from "../../../config/PageOptions.vue";
import axios from "axios";
import sipData from "../../../plugins/sipData";
import Loader from "../../../components/loader/Loader.vue";

export default {
  mixins: [sipData],
  props: ["kode_register", "no_rekam_medik", "nama_form"],
  components: {
    Loader,
  },
  data() {
    PageOptions.pageContentFullWidth = true;
    PageOptions.pageContentFullHeight = true;
    return {
      diagnosis: {
        id_diagnosis: "",
        kode_diagnosis: "",
        kode_rekam_medik: "",
        kode_register: "",
        tanggal: "",
        jam: "",
        diagnosis_awal: "",
        diagnosis_primer: "",
        diagnosis_sekunder: "",
        kode_diagnosis_awal: "",
        kode_diagnosis_primer: "",
        kode_diagnosis_sekunder: "",
      },
      search: {
        icd: "",
        pasien: "",
        gejala: "",
      },
      rows: {
        icd: [],
        pasien: [],
        gejala: [],
      },
      formValidate: [],
      loadingData: false,
      loading: false,
      timeoutId: null,
      showDiagnosaAwal: false,
      showDiagnosaPrimer: false,
      showDiagnosaSekunder: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    saveData(param) {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.diagnosis) {
        formData.append(key, this.diagnosis[key]);
      }
      formData.append("kode_rekam_medik", this.no_rekam_medik);
      formData.append("kode_register", this.kode_register);

      axios
        .post("/v1/rekammedik/diagnosis", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.$emit("formCode", param);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      this.loadingData = true;
      axios
        .get("/v1/rekammedik/diagnosis/", {
          params: {
            kode_register: this.kode_register,
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.diagnosis = response.data.data;
            this.search.diagnosis_awal =
              response.data.data.kode_diagnosis_awal +
              " : " +
              response.data.data.diagnosis_awal;
            this.search.diagnosis_primer =
              response.data.data.kode_diagnosis_primer +
              " : " +
              response.data.data.diagnosis_primer;
            this.search.diagnosis_sekunder =
              response.data.data.kode_diagnosis_sekunder +
              " : " +
              response.data.data.diagnosis_sekunder;
          }
          this.loadingData = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    toggleForm(param) {
      this.$emit("formCode", param);
    },
    handleDiagnosa(param) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.searchICD(param);
      }, 500);
    },
    searchICD(param) {
      const formData = new FormData();
      if (param == 1) {
        this.showDiagnosaAwal = true;
        formData.append("search", this.search.diagnosis_awal);
      } else if (param == 2) {
        this.showDiagnosaPrimer = true;
        formData.append("search", this.search.diagnosis_primer);
      } else {
        this.showDiagnosaSekunder = true;
        formData.append("search", this.search.diagnosis_sekunder);
      }

      axios
        .post("/v1/icdten/search/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows.icd = response.data.data || [];
          if (this.rows.icd.length <= 0) {
            this.showDiagnosaAwal = false;
            this.showDiagnosaPrimer = false;
            this.showDiagnosaSekunder = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setDiagnosa(param, kode, diagnosa) {
      if (param == 1) {
        this.showDiagnosaAwal = false;
        this.diagnosis.kode_diagnosis_awal = kode;
        this.diagnosis.diagnosis_awal = diagnosa;
        this.search.diagnosis_awal = kode + " : " + diagnosa;
      } else if (param == 2) {
        this.showDiagnosaPrimer = false;
        this.diagnosis.kode_diagnosis_primer = kode;
        this.diagnosis.diagnosis_primer = diagnosa;
        this.search.diagnosis_primer = kode + " : " + diagnosa;
      } else {
        this.showDiagnosaSekunder = false;
        this.diagnosis.kode_diagnosis_sekunder = kode;
        this.diagnosis.diagnosis_sekunder = diagnosa;
        this.search.diagnosis_sekunder = kode + " : " + diagnosa;
      }
      this.rows.icd = [];
    },
  },
};
</script>
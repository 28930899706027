<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-teal text-light">
          <h3 class="panel-title bold">Ruang Pelayanan</h3>
        </div>
        <div class="panel-body">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Masukkan NIK atau Nama',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 9,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
            :sort-options="{
              enabled: false,
            }"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-default mr-2"
                >
                  <i v-if="loading" class="fa fa-sync fa-spin"></i>
                  <i v-else class="fa fa-sync"></i>
                </button>
                <router-link
                  to="/ruang-pelayanan/create"
                  class="btn btn-primary"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Data</span>
                </router-link>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <router-link
                    :to="'/register/formulir/' + props.row.id_poliklinik"
                    class="btn btn-sm btn-success m-r-5"
                  >
                    <i class="fa fa-search"></i>
                  </router-link>
                  <button
                    class="btn btn-sm btn-danger"
                    v-on:click="confirmDelete(props.row.id_poliklinik)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  data() {
    return {
      columns: [
        {
          label: "Kode",
          field: "kode_poli",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap align-middle",
        },
        {
          label: "Ruang",
          field: "nama_poli",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap align-middle",
        },
        {
          label: "Pemeriksaan/Pelayanan",
          field: "keterangan_poli",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap align-middle",
        },
        {
          label: "Aksi",
          field: "action",
          width: "60px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      tableInfo: "Tidak ada data yang dicari",
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.rows = [];
      this.tableInfo = "Memperbarui data";
      this.loading = true;
      axios
        .get("/v1/poliklinik", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.tableInfo = "Tidak ada data yang dicari";
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/antrian/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    panggilAntrian(faskes, poli, nomor) {
      // window.alert(faskes + "-" + poli + "-" + nomor);

      const formData = new FormData();
      formData.append("faskes", faskes);
      formData.append("poli", poli);
      formData.append("nomor", nomor);

      axios
        .post("/v1/display/panggil/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    formatIndo(dateTime) {
      return moment(dateTime).format("DD MMMM YYYY");
    },
    formatUrut(poli, nomor) {
      let a = poli;
      let b = nomor.toString().padStart(3, "0");
      let urut = a + "-" + b;
      return urut;
    },
    getLabelClass(ket) {
      const classMap = {
        Menunggu: "label label-danger",
        Proses: "label label-info",
        Tunda: "label label-warning",
        Lab: "label bg-purple",
        Apotik: "label bg-pink",
        Selesai: "label bg-success",
      };

      return classMap[ket] || "";
    },
    getIconClass(ket) {
      const iconMap = {
        Menunggu: "fa fa-clock",
        Proses: "fa fa-stethoscope",
        Tunda: "fa fa-pause",
        Lab: "fa fa-flask",
        Apotik: "fa fa-flask",
        Selesai: "fa fa-check",
      };

      return iconMap[ket] || "";
    },
  },
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.postData.apply(null, arguments)}}},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nik"}},[_vm._v("NIK : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nik),expression:"form.nik"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.nik,
                    },attrs:{"type":"number","name":"nik","placeholder":"NIK"},domProps:{"value":(_vm.form.nik)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nik", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nama"}},[_vm._v("Nama : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nama),expression:"form.nama"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.nama,
                    },attrs:{"type":"text","name":"nama","placeholder":"Nama"},domProps:{"value":(_vm.form.nama)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nama", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"no_kk"}},[_vm._v("No KK : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.no_kk),expression:"form.no_kk"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.no_kk,
                    },attrs:{"type":"number","name":"no_kk","placeholder":"No. Kartu Keluarga"},domProps:{"value":(_vm.form.no_kk)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "no_kk", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nama_ibu_kandung"}},[_vm._v("Nama Ibu Kandung : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nama_ibu_kandung),expression:"form.nama_ibu_kandung"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.nama_ibu_kandung,
                    },attrs:{"type":"nama","name":"nama_ibu_kandung","placeholder":"Nama Ibu Kandung"},domProps:{"value":(_vm.form.nama_ibu_kandung)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nama_ibu_kandung", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nama_kk"}},[_vm._v("Nama KK : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nama_kk),expression:"form.nama_kk"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.nama_kk,
                    },attrs:{"type":"nama","name":"nama_kk","placeholder":"Nama Kepala Keluarga"},domProps:{"value":(_vm.form.nama_kk)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nama_kk", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"status_kk"}},[_vm._v("Status Dalam Keluarga : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.status_kk),expression:"form.status_kk"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.status_kk,
                    },attrs:{"name":"status_kk"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "status_kk", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"Suami"}},[_vm._v("Suami")]),_c('option',{attrs:{"value":"Istri"}},[_vm._v("Istri")]),_c('option',{attrs:{"value":"Anak"}},[_vm._v("Anak")]),_c('option',{attrs:{"value":"Lainnya"}},[_vm._v("Lainnya")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"status_kawin"}},[_vm._v("Status Kawin : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.status_kawin),expression:"form.status_kawin"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.status_kawin,
                    },attrs:{"name":"status_kawin"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "status_kawin", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"Kawin"}},[_vm._v("Kawin")]),_c('option',{attrs:{"value":"Belum Kawin"}},[_vm._v("Belum Kawin")]),_c('option',{attrs:{"value":"Cerai Hidup"}},[_vm._v("Cerai Hidup")]),_c('option',{attrs:{"value":"Cerai Mati"}},[_vm._v("Cerai Mati")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tempat_lahir"}},[_vm._v("Tempat Lahir : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tempat_lahir),expression:"form.tempat_lahir"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.tempat_lahir,
                    },attrs:{"type":"text","name":"tempat_lahir","placeholder":"Tempat Lahir"},domProps:{"value":(_vm.form.tempat_lahir)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "tempat_lahir", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tgl_lahir"}},[_vm._v("Tanggal Lahir : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tgl_lahir),expression:"form.tgl_lahir"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.tgl_lahir,
                    },attrs:{"type":"date","name":"tgl_lahir","placeholder":"Tgl Lahir"},domProps:{"value":(_vm.form.tgl_lahir)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "tgl_lahir", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"jenis_kelamin"}},[_vm._v("Jenis Kelamin : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.jenis_kelamin),expression:"form.jenis_kelamin"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.jenis_kelamin,
                    },attrs:{"name":"jenis_kelamin"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "jenis_kelamin", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"Laki-Laki"}},[_vm._v("Laki-Laki")]),_c('option',{attrs:{"value":"Perempuan"}},[_vm._v("Perempuan")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"jenis_jaminan"}},[_vm._v("Jenis Jaminan : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.jenis_jaminan),expression:"form.jenis_jaminan"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.jenis_jaminan,
                    },attrs:{"name":"jenis_jaminan"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "jenis_jaminan", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"Umum"}},[_vm._v("Umum")]),_c('option',{attrs:{"value":"JKN Non PBI"}},[_vm._v("JKN Non PBI")]),_c('option',{attrs:{"value":"JKN PBI"}},[_vm._v("JKN PBI")]),_c('option',{attrs:{"value":"Mandiri"}},[_vm._v("Mandiri")])])])]),(
                  _vm.form.jenis_jaminan != 'Umum' && _vm.form.jenis_jaminan != ''
                )?_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"no_kartu_jaminan"}},[_vm._v("No Kartu Jaminan : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.no_kartu_jaminan),expression:"form.no_kartu_jaminan"}],staticClass:"form-control",attrs:{"type":"number","name":"no_kartu_jaminan","placeholder":"No Kartu, Kosongkan Jika Tidak Ada"},domProps:{"value":(_vm.form.no_kartu_jaminan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "no_kartu_jaminan", $event.target.value)}}})])]):_vm._e(),(
                  _vm.form.jenis_jaminan != 'Umum' && _vm.form.jenis_jaminan != ''
                )?_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"faskes"}},[_vm._v("Faskes : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.faskes),expression:"form.faskes"}],staticClass:"form-control",attrs:{"type":"text","name":"faskes","placeholder":"Nama Faskes, Kosongkan Jika Tidak Ada","list":"listFaskes"},domProps:{"value":(_vm.form.faskes)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "faskes", $event.target.value)}}}),_c('datalist',{attrs:{"id":"listFaskes"}},_vm._l((_vm.opsiFaskes),function(faskes){return _c('option',{key:faskes.kode_faskes,domProps:{"value":faskes.nama_faskes}},[_vm._v(" "+_vm._s(faskes.nama_faskes)+" ")])}),0)])]):_vm._e(),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"agama"}},[_vm._v("Agama : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.agama),expression:"form.agama"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.agama,
                    },attrs:{"name":"agama","placeholder":"Agama"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "agama", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"Islam"}},[_vm._v("Islam")]),_c('option',{attrs:{"value":"Katolik"}},[_vm._v("Katolik")]),_c('option',{attrs:{"value":"Protestan"}},[_vm._v("Protestan")]),_c('option',{attrs:{"value":"Hindu"}},[_vm._v("Hindu")]),_c('option',{attrs:{"value":"Budha"}},[_vm._v("Budha")]),_c('option',{attrs:{"value":"Konghuchu"}},[_vm._v("Konghuchu")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"no_telpon"}},[_vm._v("No. Telpon/HP : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.no_telpon),expression:"form.no_telpon"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.no_telpon,
                    },attrs:{"type":"number","name":"no_telpon","placeholder":"No. Telpon/HP"},domProps:{"value":(_vm.form.no_telpon)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "no_telpon", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"pekerjaan"}},[_vm._v("Pekerjaan : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.pekerjaan),expression:"form.pekerjaan"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.pekerjaan,
                    },attrs:{"type":"text","name":"pekerjaan","placeholder":"Pekerjaan"},domProps:{"value":(_vm.form.pekerjaan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "pekerjaan", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"golongan_darah"}},[_vm._v("Golongan Darah : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.golongan_darah),expression:"form.golongan_darah"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.golongan_darah,
                    },attrs:{"name":"golongan_darah"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "golongan_darah", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"AB"}},[_vm._v("AB")]),_c('option',{attrs:{"value":"A"}},[_vm._v("A")]),_c('option',{attrs:{"value":"B"}},[_vm._v("B")]),_c('option',{attrs:{"value":"O"}},[_vm._v("O")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"kewarganegaraan"}},[_vm._v("Kewarganegaraan : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kewarganegaraan),expression:"form.kewarganegaraan"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.kewarganegaraan,
                    },attrs:{"name":"kewarganegaraan","placeholder":"Kewarganegaraan"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kewarganegaraan", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"WNI"}},[_vm._v("Warga Negara Indonesia")]),_c('option',{attrs:{"value":"WNA"}},[_vm._v("Warga Negara Asing")])])])]),(_vm.form.kewarganegaraan == 'WNA')?_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"no_paspor"}},[_vm._v("Nomor Paspor : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.no_paspor),expression:"form.no_paspor"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.no_paspor,
                    },attrs:{"type":"text","name":"no_paspor","placeholder":"Nomor Paspor"},domProps:{"value":(_vm.form.no_paspor)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "no_paspor", $event.target.value)}}})])]):_vm._e()]),_c('div',{staticClass:"row",staticStyle:{"margin-top":"15px"}},[_vm._m(1),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"alamat_ktp"}},[_vm._v("Alamat Tempat Tinggal : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.alamat_ktp),expression:"form.alamat_ktp"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.alamat_ktp,
                    },attrs:{"type":"text","name":"alamat_ktp","placeholder":"Masukkan Nama Jalan"},domProps:{"value":(_vm.form.alamat_ktp)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "alamat_ktp", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"provinsi_ktp"}},[_vm._v("Provinsi : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.provinsi_ktp),expression:"form.provinsi_ktp"}],staticClass:"form-control default-select2",class:{
                      'is-invalid': _vm.formValidate.provinsi_ktp,
                    },attrs:{"name":"provinsi_ktp"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "provinsi_ktp", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKabupaten($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiProvinsi),function(provinsi){return _c('option',{key:provinsi.nama_provinsi,domProps:{"value":provinsi.nama_provinsi}},[_vm._v(" "+_vm._s(provinsi.nama_provinsi)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"kabupaten_ktp"}},[_vm._v("Kabupaten/Kota : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kabupaten_ktp),expression:"form.kabupaten_ktp"}],staticClass:"form-control m-b-5",class:{
                      'is-invalid': _vm.formValidate.kabupaten_ktp,
                    },attrs:{"name":"kabupaten_ktp"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kabupaten_ktp", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKecamatan($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKabupaten),function(kabupaten){return _c('option',{key:kabupaten.nama_kabupaten,domProps:{"value":kabupaten.nama_kabupaten}},[_vm._v(" "+_vm._s(kabupaten.nama_kabupaten)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"kecamatan_ktp"}},[_vm._v("Kecamatan : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kecamatan_ktp),expression:"form.kecamatan_ktp"}],staticClass:"form-control m-b-5",class:{
                      'is-invalid': _vm.formValidate.kecamatan_ktp,
                    },attrs:{"name":"kecamatan_ktp"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kecamatan_ktp", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKelurahan($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKecamatan),function(kecamatan){return _c('option',{key:kecamatan.nama_kecamatan,domProps:{"value":kecamatan.nama_kecamatan}},[_vm._v(" "+_vm._s(kecamatan.nama_kecamatan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"kelurahan_ktp"}},[_vm._v("Kelurahan : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kelurahan_ktp),expression:"form.kelurahan_ktp"}],staticClass:"form-control m-b-5",class:{
                      'is-invalid': _vm.formValidate.kelurahan_ktp,
                    },attrs:{"name":"kelurahan_ktp"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kelurahan_ktp", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKelurahan),function(kelurahan){return _c('option',{key:kelurahan.nama_kelurahan,domProps:{"value":kelurahan.nama_kelurahan}},[_vm._v(" "+_vm._s(kelurahan.nama_kelurahan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"rw_ktp"}},[_vm._v("RW : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.rw_ktp),expression:"form.rw_ktp"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.rw_ktp,
                    },attrs:{"type":"text","name":"rw_ktp","placeholder":"RW"},domProps:{"value":(_vm.form.rw_ktp)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "rw_ktp", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"rt_ktp"}},[_vm._v("RT : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.rt_ktp),expression:"form.rt_ktp"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.rt_ktp,
                    },attrs:{"type":"text","name":"rt_ktp","placeholder":"RT"},domProps:{"value":(_vm.form.rt_ktp)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "rt_ktp", $event.target.value)}}})])])]),_c('div',{staticClass:"row",staticStyle:{"margin-top":"15px"}},[_vm._m(2),_c('div',{staticClass:"col-md-12 mb-3"},[_c('div',{staticClass:"checkbox checkbox-css"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sesuai_ktp),expression:"form.sesuai_ktp"}],attrs:{"type":"checkbox","id":"sesuai_ktp"},domProps:{"checked":Array.isArray(_vm.form.sesuai_ktp)?_vm._i(_vm.form.sesuai_ktp,null)>-1:(_vm.form.sesuai_ktp)},on:{"change":[function($event){var $$a=_vm.form.sesuai_ktp,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "sesuai_ktp", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "sesuai_ktp", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "sesuai_ktp", $$c)}},function($event){return _vm.setDomisi($event)}]}}),_c('label',{attrs:{"for":"sesuai_ktp"}},[_vm._v("Domisili sesuai dengan KTP")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"alamat_domisili"}},[_vm._v("Alamat Domisili : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.alamat_domisili),expression:"form.alamat_domisili"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.alamat_domisili,
                    },attrs:{"type":"text","name":"alamat_domisili","placeholder":"Masukkan Nama Jalan","readonly":_vm.form.sesuai_ktp === true || _vm.form.sesuai_ktp === 'true'},domProps:{"value":(_vm.form.alamat_domisili)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "alamat_domisili", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"provinsi_domisili"}},[_vm._v("Provinsi : ")]),(
                      _vm.form.sesuai_ktp === true || _vm.form.sesuai_ktp === 'true'
                    )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.provinsi_domisili),expression:"form.provinsi_domisili"}],staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.form.provinsi_domisili)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "provinsi_domisili", $event.target.value)}}}):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.provinsi_domisili),expression:"form.provinsi_domisili"}],staticClass:"form-control default-select2",class:{
                      'is-invalid': _vm.formValidate.provinsi_domisili,
                    },attrs:{"name":"provinsi_domisili"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "provinsi_domisili", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKabupatenDomisili($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiProvinsi),function(provinsi){return _c('option',{key:provinsi.nama_provinsi,domProps:{"value":provinsi.nama_provinsi}},[_vm._v(" "+_vm._s(provinsi.nama_provinsi)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"kabupaten_domisili"}},[_vm._v("Kabupaten/Kota : ")]),(
                      _vm.form.sesuai_ktp === true || _vm.form.sesuai_ktp === 'true'
                    )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kabupaten_domisili),expression:"form.kabupaten_domisili"}],staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.form.kabupaten_domisili)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "kabupaten_domisili", $event.target.value)}}}):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kabupaten_domisili),expression:"form.kabupaten_domisili"}],staticClass:"form-control m-b-5",class:{
                      'is-invalid': _vm.formValidate.kabupaten_domisili,
                    },attrs:{"name":"kabupaten_domisili"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kabupaten_domisili", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKecamatanDomisili($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKabupatenDomisili),function(kabupaten){return _c('option',{key:kabupaten.nama_kabupaten,domProps:{"value":kabupaten.nama_kabupaten}},[_vm._v(" "+_vm._s(kabupaten.nama_kabupaten)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"kecamatan_domisili"}},[_vm._v("Kecamatan : ")]),(
                      _vm.form.sesuai_ktp === true || _vm.form.sesuai_ktp === 'true'
                    )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kecamatan_domisili),expression:"form.kecamatan_domisili"}],staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.form.kecamatan_domisili)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "kecamatan_domisili", $event.target.value)}}}):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kecamatan_domisili),expression:"form.kecamatan_domisili"}],staticClass:"form-control m-b-5",class:{
                      'is-invalid': _vm.formValidate.kecamatan_domisili,
                    },attrs:{"name":"kecamatan_domisili"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kecamatan_domisili", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKelurahanDomisili($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKecamatanDomisili),function(kecamatan){return _c('option',{key:kecamatan.nama_kecamatan,domProps:{"value":kecamatan.nama_kecamatan}},[_vm._v(" "+_vm._s(kecamatan.nama_kecamatan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"kelurahan_domisili"}},[_vm._v("Kelurahan : ")]),(
                      _vm.form.sesuai_ktp === true || _vm.form.sesuai_ktp === 'true'
                    )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kelurahan_domisili),expression:"form.kelurahan_domisili"}],staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.form.kelurahan_domisili)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "kelurahan_domisili", $event.target.value)}}}):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kelurahan_domisili),expression:"form.kelurahan_domisili"}],staticClass:"form-control m-b-5",class:{
                      'is-invalid': _vm.formValidate.kelurahan_domisili,
                    },attrs:{"name":"kelurahan_domisili"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kelurahan_domisili", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKelurahanDomisili),function(kelurahan){return _c('option',{key:kelurahan.nama_kelurahan,domProps:{"value":kelurahan.nama_kelurahan}},[_vm._v(" "+_vm._s(kelurahan.nama_kelurahan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"rw_domisili"}},[_vm._v("RW : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.rw_domisili),expression:"form.rw_domisili"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.rw_domisili,
                    },attrs:{"type":"text","name":"rw_domisili","placeholder":"RW","readonly":_vm.form.sesuai_ktp === true || _vm.form.sesuai_ktp === 'true'},domProps:{"value":(_vm.form.rw_domisili)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "rw_domisili", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"rt_domisili"}},[_vm._v("RT : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.rt_domisili),expression:"form.rt_domisili"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.formValidate.rt_domisili,
                    },attrs:{"type":"text","name":"rt_domisili","placeholder":"RT","readonly":_vm.form.sesuai_ktp === true || _vm.form.sesuai_ktp === 'true'},domProps:{"value":(_vm.form.rt_domisili)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "rt_domisili", $event.target.value)}}})])])])]),_c('div',{staticClass:"panel-footer"},[_c('a',{staticClass:"btn btn-default",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Batal")]),_c('button',{staticClass:"btn btn-teal pull-right",attrs:{"type":"button","disabled":this.loading},on:{"click":function($event){return _vm.postData()}}},[(this.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.params.id ? "Update" : "Submit")+" ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-teal text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Form Pasien")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('span',{staticStyle:{"background-color":"#009688","border-radius":"5px 5px 0px 0px","padding":"10px","color":"#fff"}},[_vm._v(" Alamat Sesuai KTP ")]),_c('hr',{staticStyle:{"margin-top":"7px","border-top":"solid 2px #009688"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('span',{staticStyle:{"background-color":"#009688","border-radius":"5px 5px 0px 0px","padding":"10px","color":"#fff"}},[_vm._v(" Alamat Domisili ")]),_c('hr',{staticStyle:{"margin-top":"7px","border-top":"solid 2px #009688"}})])
}]

export { render, staticRenderFns }
import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=24e4f424&scoped=true&"
var script = {}
import style0 from "./Loader.vue?vue&type=style&index=0&id=24e4f424&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24e4f424",
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"panel panel-primary"},[_vm._m(0),_c('form',{attrs:{"id":"formPeserta","enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.postData.apply(null, arguments)}}},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id_users),expression:"form.id_users"}],staticClass:"form-control m-b-5",attrs:{"type":"text","hidden":"","name":"id_users"},domProps:{"value":(_vm.form.id_users)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "id_users", $event.target.value)}}}),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nomor_identitas"}},[_vm._v("NIK")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nomor_identitas),expression:"form.nomor_identitas"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.nomor_identitas,
                        },attrs:{"type":"text","name":"nomor_identitas"},domProps:{"value":(_vm.form.nomor_identitas)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nomor_identitas", $event.target.value)}}}),_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.nomor_identitas)}})])]),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nama_lengkap"}},[_vm._v("Nama Lengkap")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nama_lengkap),expression:"form.nama_lengkap"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.nama_lengkap,
                        },attrs:{"type":"text","name":"nama_lengkap"},domProps:{"value":(_vm.form.nama_lengkap)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nama_lengkap", $event.target.value)}}}),_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.nama_lengkap)}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.email,
                        },attrs:{"type":"email","name":"email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.email)}})])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"no_handphone"}},[_vm._v("Nomor HP/WA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.no_handphone),expression:"form.no_handphone"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.no_handphone,
                        },attrs:{"type":"text","name":"no_handphone"},domProps:{"value":(_vm.form.no_handphone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "no_handphone", $event.target.value)}}}),_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.no_handphone)}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.formValidate.password,
                        },attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('small',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.password)}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirm_password),expression:"form.confirm_password"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.formValidate.confirm_password,
                        },attrs:{"type":"password","placeholder":"Ulangi Password"},domProps:{"value":(_vm.form.confirm_password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "confirm_password", $event.target.value)}}}),_c('small',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.confirm_password)}})])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"level"}},[_vm._v("Level User")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.level),expression:"form.level"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.level,
                        },attrs:{"name":"level"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "level", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"admin"}},[_vm._v("Admin")]),_c('option',{attrs:{"value":"admin_dinkes"}},[_vm._v("Admin Dinkes")]),_c('option',{attrs:{"value":"admin_sip"}},[_vm._v("Admin SIP")]),_c('option',{attrs:{"value":"admin_sian"}},[_vm._v("Admin SIAN")]),_c('option',{attrs:{"value":"admin_ruang"}},[_vm._v("Admin Ruang")]),_c('option',{attrs:{"value":"admin_lab"}},[_vm._v("Admin Lab")]),_c('option',{attrs:{"value":"admin_apotik"}},[_vm._v("Admin Apotik")]),_c('option',{attrs:{"value":"user_kiosk"}},[_vm._v("User Kiosk")]),_c('option',{attrs:{"value":"user_display"}},[_vm._v("User Display")])]),_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.level)}})])]),(
                      _vm.form.level == 'admin_dinkes' ||
                      _vm.form.level == 'admin_sian' ||
                      _vm.form.level == 'admin_ruang' ||
                      _vm.form.level == 'admin_lab' ||
                      _vm.form.level == 'admin_apotik' ||
                      _vm.form.level == 'user_kiosk' ||
                      _vm.form.level == 'user_display'
                    )?_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"faskes"}},[_vm._v("Puskesmas")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.faskes),expression:"form.faskes"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.faskes,
                        },attrs:{"name":"faskes"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "faskes", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getHakAkses($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiFaskes),function(faskes){return _c('option',{key:faskes.kode_faskes,domProps:{"value":faskes.kode_faskes}},[_vm._v(" "+_vm._s(faskes.nama_faskes)+" ")])})],2),_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.level)}})])]):_vm._e(),(_vm.form.level == 'admin_ruang')?_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"hak_akses"}},[_vm._v("Pemeriksaan")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hak_akses),expression:"form.hak_akses"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.hak_akses,
                        },attrs:{"name":"hak_akses"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "hak_akses", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiHakAkses),function(hak_akses){return _c('option',{key:hak_akses.id_poliklinik,domProps:{"value":hak_akses.id_poliklinik}},[_vm._v(" "+_vm._s(hak_akses.nama_poli)+" ")])})],2),_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.hak_akses)}})])]):_vm._e()])])])]),_c('div',{staticClass:"panel-footer"},[_c('a',{staticClass:"btn btn-default",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Batal")]),_c('button',{staticClass:"btn btn-teal pull-right",attrs:{"type":"button","disabled":this.loading},on:{"click":function($event){return _vm.postData()}}},[(this.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.params.id ? "Update" : "Submit")+" ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-teal text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Form")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"password"}},[_vm._v("Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"confirm_password"}},[_vm._v("Ulangi Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }
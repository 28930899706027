var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vertical-box"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"btn-toolbar"},[_c('div',{staticClass:"btn-group btn-block m-r-5"},[_c('a',{staticClass:"btn btn-default btn-sm p-l-auto p-r-auto",staticStyle:{"font-weight":"bold"},attrs:{"href":"javascript:;"}},[_vm._v(" "+_vm._s(_vm.nama_form)+" ")])])])]),_c('div',{staticClass:"vertical-box-row bg-white"},[_c('div',{staticClass:"vertical-box-cell"},[_c('div',{staticClass:"vertical-box-inner-cell"},[_c('vue-custom-scrollbar',{staticClass:"height-full"},[_c('div',{staticClass:"container mt-3"},[_c('div',[_c('div',{staticClass:"row"},[(this.loadingData)?_c('Loader'):_c('div',{staticClass:"container"},[_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right"},[_vm._v("Waktu")]),_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"input-group"},[_c('span',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",staticStyle:{"height":"calc(1.5em + 0.875rem + 2px)"}},[_vm._v("Tanggal")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.persetujuan_tindakan.tanggal_persetujuan_tindakan
                          ),expression:"\n                            persetujuan_tindakan.tanggal_persetujuan_tindakan\n                          "}],staticClass:"form-control col-md-3",class:{
                            'is-invalid':
                              _vm.formValidate.tanggal_persetujuan_tindakan,
                          },attrs:{"type":"date"},domProps:{"value":(
                            _vm.persetujuan_tindakan.tanggal_persetujuan_tindakan
                          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.persetujuan_tindakan, "tanggal_persetujuan_tindakan", $event.target.value)}}}),_c('span',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",staticStyle:{"height":"calc(1.5em + 0.875rem + 2px)"}},[_vm._v("Jam")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.persetujuan_tindakan.jam_persetujuan_tindakan
                          ),expression:"\n                            persetujuan_tindakan.jam_persetujuan_tindakan\n                          "}],staticClass:"form-control col-md-2 m-b-5",class:{
                            'is-invalid':
                              _vm.formValidate.jam_persetujuan_tindakan,
                          },attrs:{"type":"time"},domProps:{"value":(
                            _vm.persetujuan_tindakan.jam_persetujuan_tindakan
                          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.persetujuan_tindakan, "jam_persetujuan_tindakan", $event.target.value)}}})])])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"dokter_pemberi_penjelasan"}},[_vm._v("Dokter yang Memberi Penjelasan")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                          _vm.persetujuan_tindakan.dokter_pemberi_penjelasan
                        ),expression:"\n                          persetujuan_tindakan.dokter_pemberi_penjelasan\n                        "}],staticClass:"form-control m-b-5",class:{
                          'is-invalid':
                            _vm.formValidate.dokter_pemberi_penjelasan,
                        },domProps:{"value":(
                          _vm.persetujuan_tindakan.dokter_pemberi_penjelasan
                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.persetujuan_tindakan, "dokter_pemberi_penjelasan", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"petugas_pendamping"}},[_vm._v("Petugas yang Mendampingi")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.persetujuan_tindakan.petugas_pendamping),expression:"persetujuan_tindakan.petugas_pendamping"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.petugas_pendamping,
                        },domProps:{"value":(_vm.persetujuan_tindakan.petugas_pendamping)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.persetujuan_tindakan, "petugas_pendamping", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"keluarga_pasien"}},[_vm._v("Keluarga Pasien")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.persetujuan_tindakan.keluarga_pasien),expression:"persetujuan_tindakan.keluarga_pasien"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.keluarga_pasien,
                        },domProps:{"value":(_vm.persetujuan_tindakan.keluarga_pasien)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.persetujuan_tindakan, "keluarga_pasien", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"tindakan_dilakukan"}},[_vm._v("Tindakan yang Dilakukan")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.persetujuan_tindakan.tindakan_dilakukan),expression:"persetujuan_tindakan.tindakan_dilakukan"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.tindakan_dilakukan,
                        },domProps:{"value":(_vm.persetujuan_tindakan.tindakan_dilakukan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.persetujuan_tindakan, "tindakan_dilakukan", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"konsekuensi_tindakan"}},[_vm._v("Konsekuensi dari Tindakan")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.persetujuan_tindakan.konsekuensi_tindakan),expression:"persetujuan_tindakan.konsekuensi_tindakan"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.konsekuensi_tindakan,
                        },domProps:{"value":(_vm.persetujuan_tindakan.konsekuensi_tindakan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.persetujuan_tindakan, "konsekuensi_tindakan", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right"},[_vm._v("Saksi")]),_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"input-group"},[_c('span',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",staticStyle:{"height":"calc(1.5em + 0.875rem + 2px)"}},[_vm._v("Saksi 1")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.persetujuan_tindakan.saksi_1),expression:"persetujuan_tindakan.saksi_1"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.saksi_1,
                          },domProps:{"value":(_vm.persetujuan_tindakan.saksi_1)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.persetujuan_tindakan, "saksi_1", $event.target.value)}}}),_c('span',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",staticStyle:{"height":"calc(1.5em + 0.875rem + 2px)"}},[_vm._v("Saksi 2")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.persetujuan_tindakan.saksi_2),expression:"persetujuan_tindakan.saksi_2"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.saksi_2,
                          },domProps:{"value":(_vm.persetujuan_tindakan.saksi_2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.persetujuan_tindakan, "saksi_2", $event.target.value)}}})])])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('div',{staticClass:"col-md-3"},[_c('label',{staticClass:"col-form-label push-right",attrs:{"for":"persetujuan_pasien"}},[_vm._v("Persetujuan / Penolakan Tindakan")])]),_c('div',{staticClass:"col-md-3"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.persetujuan_tindakan.persetujuan_pasien),expression:"persetujuan_tindakan.persetujuan_pasien"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.formValidate.persetujuan_pasien,
                        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.persetujuan_tindakan, "persetujuan_pasien", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Ya"}},[_vm._v("Ya")]),_c('option',{attrs:{"value":"Tidak"}},[_vm._v("Tidak")])])])])])],1)])])])],1)])]),_c('div',{staticClass:"wrapper clearfix"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleForm(7)}}},[_vm._v(" Sebelumnya ")]),(!this.loadingData)?_c('button',{staticClass:"btn btn-teal pull-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveData(9)}}},[(this.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" Selanjutnya ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
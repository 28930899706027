<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-teal text-light">
            <h3 class="panel-title bold">Detail Pasien</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="nik">NIK : </label>
                  <input
                    v-model="detail.nik"
                    type="number"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="nama">Nama : </label>
                  <input
                    v-model="detail.nama"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="no_kk">No KK : </label>
                  <input
                    v-model="detail.no_kk"
                    type="number"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="nama_ibu_kandung">Nama Ibu Kandung : </label>
                  <input
                    v-model="detail.nama_ibu_kandung"
                    class="form-control"
                    readonly
                    type="text"
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="nama_kk">Nama KK : </label>
                  <input
                    v-model="detail.nama_kk"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="status_kk">Status Dalam Keluarga : </label>
                  <input
                    v-model="detail.status_kk"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="status_kawin">Status Kawin : </label>
                  <input
                    v-model="detail.status_kawin"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="tempat_lahir">Tempat Lahir : </label>
                  <input
                    v-model="detail.tempat_lahir"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="tgl_lahir">Tanggal Lahir : </label>
                  <input
                    v-model="detail.tgl_lahir"
                    type="date"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="jenis_kelamin">Jenis Kelamin : </label>
                  <input
                    v-model="detail.jenis_kelamin"
                    type="date"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="jenis_jaminan">Jenis Jaminan : </label>
                  <input
                    v-model="detail.jenis_jaminan"
                    type="date"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div
                class="col-md-3"
                v-if="
                  detail.jenis_jaminan != 'Umum' && detail.jenis_jaminan != ''
                "
              >
                <div class="form-group">
                  <label for="no_kartu_jaminan">No Kartu Jaminan : </label>
                  <input
                    v-model="detail.no_kartu_jaminan"
                    type="number"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div
                class="col-md-3"
                v-if="
                  detail.jenis_jaminan != 'Umum' && detail.jenis_jaminan != ''
                "
              >
                <div class="form-group">
                  <label for="faskes">Faskes : </label>
                  <input
                    v-model="detail.faskes"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="agama">Agama : </label>
                  <input
                    v-model="detail.agama"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="no_telpon">No. Telpon/HP : </label>
                  <input
                    v-model="detail.no_telpon"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="pekerjaan">Pekerjaan : </label>
                  <input
                    v-model="detail.pekerjaan"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="golongan_darah">Golongan Darah : </label>
                  <input
                    v-model="detail.golongan_darah"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="kewarganegaraan">Kewarganegaraan : </label>
                  <input
                    v-model="detail.kewarganegaraan"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-md-3" v-if="detail.kewarganegaraan == 'WNA'">
                <div class="form-group">
                  <label for="no_paspor">Nomor Paspor : </label>
                  <input
                    v-model="detail.no_paspor"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>

            <div class="row" style="margin-top: 15px">
              <div class="col-md-12">
                <span
                  style="
                    background-color: #009688;
                    border-radius: 5px 5px 0px 0px;
                    padding: 10px;
                    color: #fff;
                  "
                >
                  Alamat Sesuai KTP
                </span>
                <hr style="margin-top: 7px; border-top: solid 2px #009688" />
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="alamat_ktp">Alamat Tempat Tinggal : </label>
                  <input
                    v-model="detail.alamat_ktp"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="provinsi_ktp">Provinsi : </label>
                  <input
                    v-model="detail.provinsi_ktp"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="kabupaten_ktp">Kabupaten/Kota : </label>
                  <input
                    v-model="detail.kabupaten_ktp"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="kecamatan_ktp">Kecamatan : </label>
                  <input
                    v-model="detail.kecamatan_ktp"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="kelurahan_ktp">Kelurahan : </label>
                  <input
                    v-model="detail.kelurahan_ktp"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="rw_ktp">RW : </label>
                  <input
                    v-model="detail.rw_ktp"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="rt_ktp">RT : </label>
                  <input
                    v-model="detail.rt_ktp"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>

            <div class="row" style="margin-top: 15px">
              <div class="col-md-12">
                <span
                  style="
                    background-color: #009688;
                    border-radius: 5px 5px 0px 0px;
                    padding: 10px;
                    color: #fff;
                  "
                >
                  Alamat Domisili
                </span>
                <hr style="margin-top: 7px; border-top: solid 2px #009688" />
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="alamat_domisili">Alamat Domisili : </label>
                  <input
                    v-model="detail.alamat_domisili"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="provinsi_domisili">Provinsi : </label>
                  <input
                    type="text"
                    v-model="detail.provinsi_domisili"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="kabupaten_domisili">Kabupaten/Kota : </label>
                  <input
                    type="text"
                    v-model="detail.kabupaten_domisili"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="kecamatan_domisili">Kecamatan : </label>
                  <input
                    type="text"
                    v-model="detail.kecamatan_domisili"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="kelurahan_domisili">Kelurahan : </label>
                  <input
                    type="text"
                    v-model="detail.kelurahan_domisili"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="rw_domisili">RW : </label>
                  <input
                    v-model="detail.rw_domisili"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="rt_domisili">RT : </label>
                  <input
                    v-model="detail.rt_domisili"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      detail: {
        id_iup: "",
        kode_pasien: "",
        kode_faskes: "",
        nama: "",
        no_rm: "",
        kewarganegaraan: "",
        nik: "",
        no_paspor: "",
        nama_ibu_kandung: "",
        tempat_lahir: "",
        tgl_lahir: "",
        jenis_kelamin: "",
        jenis_jaminan: "",
        no_kartu_jaminan: "",
        faskes: "",
        agama: "",
        suku: "",
        bahasa_dikuasai: "",
        alamat_ktp: "",
        rt_ktp: "",
        rw_ktp: "",
        kelurahan_ktp: "",
        kecamatan_ktp: "",
        kabupaten_ktp: "",
        provinsi_ktp: "",
        kode_pos_ktp: "",
        negara_ktp: "",
        alamat_domisili: "",
        rt_domisili: "",
        rw_domisili: "",
        kelurahan_domisili: "",
        kecamatan_domisili: "",
        kabupaten_domisili: "",
        provinsi_domisili: "",
        kode_pos_domisili: "",
        negara_domisili: "",
        no_telpon: "",
        pendidikan: "",
        pekerjaan: "",
        status_kawin: "",
        no_kk: "",
        nama_kk: "",
        status_kk: "",
        golongan_darah: "",
        sesuai_ktp: false,
      },
      loading: false,
      showUserAccess: true,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.detail.id_iup = id;
      this.fetchData();
    } else {
      this.detail.id = "";
    }
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/identitas_umum_pasien/detail/" + this.detail.id_iup)
        .then((response) => {
          console.log(response.data.data);
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>

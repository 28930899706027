<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-teal text-light">
          <h3 class="panel-title bold">Detail</h3>
        </div>
        <div class="panel-body">
          <div class="container" v-if="loading">
            <div
              class="row justify-content-center align-items-center"
              style="height: 50vh"
            >
              <div class="col-12 text-center">
                <span>
                  <i class="fas fa-circle-notch f-s-25 text-teal fa-spin"></i>
                </span>
              </div>
            </div>
          </div>

          <table v-if="!this.loading" class="table table-striped">
            <tr>
              <td>Tanggal/Jam</td>
              <td style="width: 5px">:</td>
              <td>{{ formatIndo(detail.tanggal) }} - {{ detail.jam }}</td>
            </tr>

            <tr>
              <td>NIK</td>
              <td>:</td>
              <td>{{ detail.pasien }}</td>
            </tr>

            <tr>
              <td>Nama</td>
              <td>:</td>
              <td>{{ detail.nama_pasien }}</td>
            </tr>

            <tr>
              <td>Gejala, Penyebab Penyakit Atau Kondisi Pasien</td>
              <td>:</td>
              <td>{{ detail.gejala }}</td>
            </tr>

            <tr>
              <td>Anamnesa & Pemeriksaan</td>
              <td>:</td>
              <td>{{ detail.anamnesa }}</td>
            </tr>

            <tr>
              <td>Diagnosa & Kode ICD</td>
              <td>:</td>
              <td>{{ detail.diagnosa }} - {{ detail.ket_diagnosa }}</td>
            </tr>

            <tr>
              <td>Perencanaan Layanan</td>
              <td>:</td>
              <td>{{ detail.tindakan }}</td>
            </tr>

            <tr>
              <td>Petugas</td>
              <td>:</td>
              <td>{{ detail.petugas }}</td>
            </tr>
          </table>
        </div>
        <div class="panel-footer">
          <a href="javascript:window.history.go(-1);" class="btn btn-default"
            >Kembali</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../../plugins/sipData";
import moment from "moment";

export default {
  mixins: [sipData],
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  data() {
    return {
      detail: {
        id_register: "",
        nama_pasien: "",
        kode_register: "",
        kode_faskes: "",
        pasien: "",
        tanggal: "",
        jam: "",
        anamnesa: "",
        gejala: "",
        diagnosa: "",
        tindakan: "",
        petugas: "",
        ket_diagnosa: "",
      },
      loading: false,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.fetchData(id);
  },
  methods: {
    fetchData(param) {
      this.loading = true;
      axios
        .get("/api/register/detail/" + param)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    formatIndo(dateTime) {
      return moment(dateTime).format("DD MMMM YYYY");
    },
  },
};
</script>

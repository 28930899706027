var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{
            enabled: true,
            placeholder: 'Masukkan NIK atau Nama',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 9,
            perPageDropdownEnabled: false,
            position: 'bottom',
            nextLabel: '',
            prevLabel: '',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
          },"sort-options":{
            enabled: false,
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('div',[_c('router-link',{staticClass:"btn btn-sm btn-success m-r-5",attrs:{"to":'/register/formulir/' + props.row.id_poliklinik}},[_c('i',{staticClass:"fa fa-search"})]),_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.id_poliklinik)}}},[_c('i',{staticClass:"fa fa-trash-alt"})])],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-default mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[(_vm.loading)?_c('i',{staticClass:"fa fa-sync fa-spin"}):_c('i',{staticClass:"fa fa-sync"})]),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/ruang-pelayanan/create"}},[_c('i',{staticClass:"fa fa-plus d-md-none"}),_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Tambah Data")])])],1)])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-teal text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Ruang Pelayanan")])])
}]

export { render, staticRenderFns }
<template>
  <div class="vertical-box">
    <div class="wrapper">
      <div class="btn-toolbar">
        <div class="btn-group btn-block m-r-5">
          <a
            href="javascript:;"
            class="btn btn-default btn-sm p-l-auto p-r-auto"
            style="font-weight: bold"
          >
            {{ nama_form }}
          </a>
        </div>
      </div>
    </div>

    <div class="vertical-box-row bg-white">
      <div class="vertical-box-cell">
        <div class="vertical-box-inner-cell">
          <vue-custom-scrollbar class="height-full">
            <div class="container mt-3">
              <div>
                <div class="row">
                  <Loader v-if="this.loadingData"></Loader>
                  <div v-else class="container">
                    <div class="form-group row m-b-15">
                      <div class="col-md-12">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th colspan="4">Riwayat Penggunaan Obat</th>
                            </tr>
                            <tr>
                              <th class="text-center align-middle">
                                Nama Obat
                              </th>
                              <th
                                class="text-center align-middle"
                                style="width: 150px"
                              >
                                Dosis
                              </th>
                              <th
                                class="text-center align-middle"
                                style="width: 200px"
                              >
                                Waktu Penggunaan
                              </th>
                              <th
                                class="text-center align-middle"
                                style="width: 50px"
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="riwayat_obat in riwayat_penggunaan_obat_list"
                              :key="riwayat_obat.id_riwayat_penggunaan_obat"
                            >
                              <td class="text-center align-middle">
                                {{ riwayat_obat.nama_obat }}
                              </td>
                              <td class="text-center align-middle">
                                {{ riwayat_obat.dosis }}
                              </td>
                              <td class="text-center align-middle">
                                {{ riwayat_obat.waktu_penggunaan }}
                              </td>
                              <td class="text-center align-middle">
                                <button
                                  @click="
                                    deleteRiwayatPenggunaanObat(
                                      riwayat_obat.id_riwayat_penggunaan_obat
                                    )
                                  "
                                  :class="{
                                    'btn btn-sm btn-danger': true,
                                    loading:
                                      loadingDelete[
                                        riwayat_obat.id_riwayat_penggunaan_obat
                                      ],
                                  }"
                                >
                                  <i
                                    v-if="
                                      loadingDelete[
                                        riwayat_obat.id_riwayat_penggunaan_obat
                                      ]
                                    "
                                    class="fas fa-circle-notch fa-spin"
                                  ></i>
                                  <i v-else class="fa fa-times"></i>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  v-model="riwayat_penggunaan_obat.nama_obat"
                                  class="form-control form-control-sm"
                                  :class="{
                                    'is-invalid': formValidate.nama_obat,
                                  }"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  v-model="riwayat_penggunaan_obat.dosis"
                                  class="form-control form-control-sm"
                                  :class="{
                                    'is-invalid': formValidate.dosis,
                                  }"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  v-model="
                                    riwayat_penggunaan_obat.waktu_penggunaan
                                  "
                                  class="form-control form-control-sm"
                                  :class="{
                                    'is-invalid': formValidate.waktu_penggunaan,
                                  }"
                                />
                              </td>
                              <td>
                                <button
                                  class="btn btn-sm btn-info"
                                  @click="saveRiwayatPenggunaanObat()"
                                >
                                  <i
                                    v-if="this.loadingObat"
                                    class="fas fa-circle-notch fa-spin"
                                  ></i>
                                  <i v-else class="fa fa-plus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="rencana_pemulangan_pasien"
                        >Perencanaan Pemulangan Pasien</label
                      >
                      <div class="col-md-9">
                        <select
                          v-model="
                            pemeriksaan_spesialistik.rencana_pemulangan_pasien
                          "
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid':
                              formValidate.rencana_pemulangan_pasien,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="Pasien lansia">Pasien lansia</option>
                          <option value="Gangguan anggota gerak">
                            Gangguan anggota gerak
                          </option>
                          <option
                            value="Pasien dengan perawatan berkelanjutan atau panjang"
                          >
                            Pasien dengan perawatan berkelanjutan atau panjang
                          </option>
                          <option
                            value="Memerlukan bantuan dalam aktivitas sehari-hari"
                          >
                            Memerlukan bantuan dalam aktivitas sehari-hari
                          </option>
                          <option value="Tidak masuk kriteria">
                            Tidak masuk kriteria
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="rencana_rawat"
                        >Rencana Rawat</label
                      >
                      <div class="col-md-9">
                        <textarea
                          v-model="pemeriksaan_spesialistik.rencana_rawat"
                          class="form-control m-b-5"
                          rows="4"
                          :class="{
                            'is-invalid': formValidate.rencana_rawat,
                          }"
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="instruksi_medik_keperawatan"
                        >Instruksi Medik dan Keperawatan</label
                      >
                      <div class="col-md-9">
                        <textarea
                          v-model="
                            pemeriksaan_spesialistik.instruksi_medik_keperawatan
                          "
                          class="form-control m-b-5"
                          rows="4"
                          :class="{
                            'is-invalid':
                              formValidate.instruksi_medik_keperawatan,
                          }"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-custom-scrollbar>
        </div>
      </div>
    </div>

    <div class="wrapper clearfix">
      <button type="button" class="btn btn-default" @click="toggleForm(4)">
        Sebelumnya
      </button>
      <button
        v-if="!this.loadingData"
        type="button"
        class="btn btn-teal pull-right"
        @click="saveData(7)"
      >
        <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
        Selanjutnya
      </button>
    </div>
  </div>
</template>

<script>
import PageOptions from "../../../config/PageOptions.vue";
import axios from "axios";
import sipData from "../../../plugins/sipData";
import Loader from "../../../components/loader/Loader.vue";

export default {
  mixins: [sipData],
  props: ["kode_register", "no_rekam_medik", "nama_form"],
  components: {
    Loader,
  },
  data() {
    PageOptions.pageContentFullWidth = true;
    PageOptions.pageContentFullHeight = true;
    return {
      pemeriksaan_spesialistik: {
        id_pemeriksaan_spesialistik: "",
        kode_pemeriksaan_spesialistik: "",
        kode_rekam_medik: "",
        kode_register: "",
        rencana_pemulangan_pasien: "",
        rencana_rawat: "",
        instruksi_medik_keperawatan: "",
      },
      riwayat_penggunaan_obat: {
        nama_obat: "",
        dosis: "",
        waktu_penggunaan: "",
      },
      riwayat_penggunaan_obat_list: [],
      formValidate: [],
      loadingData: false,
      loadingObat: false,
      loadingDelete: {},
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
    this.fetcRiwayatPenggunaanObat();
  },
  methods: {
    saveData(param) {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.pemeriksaan_spesialistik) {
        formData.append(key, this.pemeriksaan_spesialistik[key]);
      }
      formData.append("kode_rekam_medik", this.no_rekam_medik);
      formData.append("kode_register", this.kode_register);

      axios
        .post("/v1/rekammedik/pemeriksaan_spesialistik", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.$emit("formCode", param);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    saveRiwayatPenggunaanObat() {
      this.loadingObat = true;

      const formData = new FormData();
      for (let key in this.riwayat_penggunaan_obat) {
        formData.append(key, this.riwayat_penggunaan_obat[key]);
      }
      formData.append("kode_rekam_medik", this.no_rekam_medik);
      formData.append("kode_register", this.kode_register);

      axios
        .post("/v1/rekammedik/riwayat_penggunaan_obat", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loadingObat = false;
            this.formValidate = response.data.message;
          } else {
            this.loadingObat = false;
            this.fetcRiwayatPenggunaanObat();
            for (let key in this.riwayat_penggunaan_obat) {
              this.riwayat_penggunaan_obat[key] = "";
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      this.loadingData = true;
      axios
        .get("/v1/rekammedik/pemeriksaan_spesialistik/", {
          params: {
            kode_register: this.kode_register,
          },
        })
        .then((response) => {
          for (let key in response.data.data) {
            this.pemeriksaan_spesialistik[key] = response.data.data[key];
          }
          this.loadingData = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetcRiwayatPenggunaanObat() {
      axios
        .get("/v1/rekammedik/riwayat_penggunaan_obat/", {
          params: {
            kode_register: this.kode_register,
          },
        })
        .then((response) => {
          this.riwayat_penggunaan_obat_list = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    deleteRiwayatPenggunaanObat(param) {
      // Set status loading hanya untuk item yang sedang dihapus
      this.$set(this.loadingDelete, param, true);

      axios
        .get(`/v1/delete/riwayat_penggunaan_obat/${param}`)
        .then((response) => {
          console.log(response.status);
          this.fetcRiwayatPenggunaanObat();
          // Reset status loading setelah penghapusan berhasil
          this.$set(this.loadingDelete, param, false);
        })
        .catch((error) => {
          // Reset status loading jika terjadi error
          this.$set(this.loadingDelete, param, false);
          console.log(error);
          this.error = error.message;
        });
    },

    toggleForm(param) {
      this.$emit("formCode", param);
    },
  },
};
</script>
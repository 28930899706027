<template>
  <div>
    <h1 class="page-header">Dashboard <small></small></h1>

    <div class="row"></div>
    <div class="row">
      <div class="col-md-4">
        <div class="widget widget-stats bg-primary">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-users fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">Kunjungan Hari Ini</div>
            <div class="stats-number">{{ this.kunjungan_hari }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="widget widget-stats bg-info">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-users fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">Kunjungan Bulan Ini</div>
            <div class="stats-number">{{ this.kunjungan_bulan }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="widget widget-stats bg-warning">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-users fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">Kunjungan Tahun Ini</div>
            <div class="stats-number">{{ this.kunjungan_tahun }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div ref="barChart"></div>
      </div>
      <div class="col-md-6">
        <div ref="pieChart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../plugins/sipData";

export default {
  mixins: [sipData],
  name: "dashboard",
  data() {
    return {
      kunjungan_hari: 0,
      kunjungan_bulan: 0,
      kunjungan_tahun: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get("/v1/dashboard/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.kunjungan_hari = response.data.kunjungan_hari;
          this.kunjungan_bulan = response.data.kunjungan_bulan;
          this.kunjungan_tahun = response.data.kunjungan_tahun;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
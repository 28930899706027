import { render, staticRenderFns } from "./Resep.vue?vue&type=template&id=01e33762&scoped=true&"
import script from "./Resep.vue?vue&type=script&lang=js&"
export * from "./Resep.vue?vue&type=script&lang=js&"
import style0 from "./Resep.vue?vue&type=style&index=0&id=01e33762&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01e33762",
  null
  
)

export default component.exports
<template>
  <div class="vertical-box">
    <div class="wrapper">
      <div class="btn-toolbar">
        <div class="btn-group btn-block m-r-5">
          <a
            href="javascript:;"
            class="btn btn-default btn-sm p-l-auto p-r-auto"
            style="font-weight: bold"
          >
            {{ nama_form }}
          </a>
        </div>
      </div>
    </div>

    <div class="vertical-box-row bg-white">
      <div class="vertical-box-cell">
        <div class="vertical-box-inner-cell">
          <vue-custom-scrollbar class="height-full">
            <div class="container mt-3">
              <div>
                <div class="row">
                  <Loader v-if="this.loadingData"></Loader>
                  <div v-else class="container">
                    <div v-if="this.userAccess != 'igd'">
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="assesmen_nyeri"
                          >Asesmen nyeri</label
                        >
                        <div class="col-md-9">
                          <select
                            v-model="pemeriksaan_fisik.assesmen_nyeri"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.assesmen_nyeri,
                            }"
                          >
                            <option value="Ada">Ada</option>
                            <option value="Tidak Ada">Tidak Ada</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="skala_nyeri"
                          >Skala nyeri</label
                        >
                        <div class="col-md-9">
                          <ul class="pagination">
                            <li
                              class="page-item text-center"
                              v-for="skala in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                              :key="skala"
                              :class="{
                                active:
                                  parseInt(pemeriksaan_fisik.skala_nyeri) ===
                                  skala,
                              }"
                              style="min-width: 40px; cursor: pointer"
                            >
                              <span
                                class="page-link"
                                @click="pemeriksaan_fisik.skala_nyeri = skala"
                                >{{ skala }}</span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="lokasi_nyeri"
                          >Lokasi nyeri</label
                        >
                        <div class="col-md-9">
                          <input
                            type="text"
                            v-model="pemeriksaan_fisik.lokasi_nyeri"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.lokasi_nyeri,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="penyebab_nyeri"
                          >Penyebab nyeri</label
                        >
                        <div class="col-md-9">
                          <input
                            type="text"
                            v-model="pemeriksaan_fisik.penyebab_nyeri"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.penyebab_nyeri,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="durasi_nyeri"
                          >Durasi nyeri</label
                        >
                        <div class="col-md-9">
                          <input
                            type="text"
                            v-model="pemeriksaan_fisik.durasi_nyeri"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.durasi_nyeri,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="frekuensi_nyeri"
                          >Frekuensi nyeri</label
                        >
                        <div class="col-md-9">
                          <input
                            type="text"
                            v-model="pemeriksaan_fisik.frekuensi_nyeri"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.frekuensi_nyeri,
                            }"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label
                          class="col-md-3 col-form-label push-right"
                          for="risiko_jatuh"
                          >Kajian Risiko jatuh</label
                        >
                        <div class="col-md-9">
                          <select
                            v-model="pemeriksaan_fisik.risiko_jatuh"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.risiko_jatuh,
                            }"
                          >
                            <option value="0 - 24 (risiko rendah)">
                              0 - 24 (risiko rendah)
                            </option>
                            <option value="25 - 44 (risiko sedang)">
                              25 - 44 (risiko sedang)
                            </option>
                            <option value="> 45 (risiko tinggi)">
                              > 45 (risiko tinggi)
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="keadaaan_umum"
                        >Keadaan Umum</label
                      >
                      <div class="col-md-9">
                        <select
                          v-model="pemeriksaan_fisik.keadaaan_umum"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.keadaaan_umum,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="Baik">Baik</option>
                          <option value="Sedang">Sedang</option>
                          <option value="Lemah">Lemah</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="tingkat_kesadaran"
                        >Tingkat kesadaran</label
                      >
                      <div class="col-md-9">
                        <select
                          v-model="pemeriksaan_fisik.tingkat_kesadaran"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.tingkat_kesadaran,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="Sadar Baik/Alert : 0">
                            Sadar Baik/Alert : 0
                          </option>
                          <option value="Berespon dengan  kata-kata/Voice : 1">
                            Berespon dengan kata-kata/Voice : 1
                          </option>
                          <option
                            value="Hanya berespons  jika dirangsang  nyeri/pain : 2"
                          >
                            Hanya berespons jika dirangsang nyeri/pain : 2
                          </option>
                          <option value="Pasien tidak  sadar/unresponsive  : 3">
                            Pasien tidak sadar/unresponsive : 3
                          </option>
                          <option value="Gelisah atau  bingung : 4">
                            Gelisah atau bingung : 4
                          </option>
                          <option value="Acute Confusional  States : 5">
                            Acute Confusional States : 5
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="tingkat_kesadaran"
                        >Tinggi Badan (cm)</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="pemeriksaan_fisik.tinggi_badan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.tinggi_badan,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="tingkat_kesadaran"
                        >Berat Badan (kg)</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="pemeriksaan_fisik.berat_badan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.berat_badan,
                          }"
                        />
                      </div>
                    </div>
                    <div class="panel">
                      <div class="panel-heading bg-grey-transparent-2">
                        Tanda-tanda Vital
                      </div>
                      <div class="panel-body">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.denyut_jantung"
                                  type="text"
                                  class="form-control"
                                  placeholder="Denyut jantung"
                                  :class="{
                                    'is-invalid': formValidate.denyut_jantung,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.pernapasan"
                                  type="text"
                                  class="form-control"
                                  placeholder="Pernapasan"
                                  :class="{
                                    'is-invalid': formValidate.pernapasan,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.sistole"
                                  type="int"
                                  class="form-control"
                                  placeholder="*Sistole"
                                  :class="{
                                    'is-invalid': formValidate.sistole,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.diastole"
                                  type="int"
                                  class="form-control"
                                  placeholder="*Diastole"
                                  :class="{
                                    'is-invalid': formValidate.diastole,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.suhu_tubuh"
                                  type="text"
                                  class="form-control"
                                  placeholder="Suhu tubuh"
                                  :class="{
                                    'is-invalid': formValidate.suhu_tubuh,
                                  }"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel">
                      <div class="panel-heading bg-grey-transparent-2">
                        Tanda-tanda Fisik
                      </div>
                      <div class="panel-body">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.kepala"
                                  type="text"
                                  class="form-control"
                                  placeholder="Kepala"
                                  :class="{
                                    'is-invalid': formValidate.kepala,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.mata"
                                  type="text"
                                  class="form-control"
                                  placeholder="Mata"
                                  :class="{
                                    'is-invalid': formValidate.mata,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.telinga"
                                  type="text"
                                  class="form-control"
                                  placeholder="Telinga"
                                  :class="{
                                    'is-invalid': formValidate.telinga,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.hidung"
                                  type="text"
                                  class="form-control"
                                  placeholder="Hidung"
                                  :class="{
                                    'is-invalid': formValidate.hidung,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.rambut"
                                  type="text"
                                  class="form-control"
                                  placeholder="Rambut"
                                  :class="{
                                    'is-invalid': formValidate.rambut,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.bibir"
                                  type="text"
                                  class="form-control"
                                  placeholder="Bibir"
                                  :class="{
                                    'is-invalid': formValidate.bibir,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.gigi_geligi"
                                  type="text"
                                  class="form-control"
                                  placeholder="Gigi Geligi"
                                  :class="{
                                    'is-invalid': formValidate.gigi_geligi,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.lidah"
                                  type="text"
                                  class="form-control"
                                  placeholder="Lidah"
                                  :class="{
                                    'is-invalid': formValidate.lidah,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.langit_langit"
                                  type="text"
                                  class="form-control"
                                  placeholder="Langit-langit"
                                  :class="{
                                    'is-invalid': formValidate.langit_langit,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.leher"
                                  type="text"
                                  class="form-control"
                                  placeholder="Leher"
                                  :class="{
                                    'is-invalid': formValidate.leher,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.tenggorokan"
                                  type="text"
                                  class="form-control"
                                  placeholder="Tenggorokan"
                                  :class="{
                                    'is-invalid': formValidate.tenggorokan,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.tonsil"
                                  type="text"
                                  class="form-control"
                                  placeholder="Tonsil"
                                  :class="{
                                    'is-invalid': formValidate.tonsil,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.dada"
                                  type="text"
                                  class="form-control"
                                  placeholder="Dada"
                                  :class="{
                                    'is-invalid': formValidate.dada,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.payudara"
                                  type="text"
                                  class="form-control"
                                  placeholder="Payudara"
                                  :class="{
                                    'is-invalid': formValidate.payudara,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.punggung"
                                  type="text"
                                  class="form-control"
                                  placeholder="Punggung"
                                  :class="{
                                    'is-invalid': formValidate.punggung,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.perut"
                                  type="text"
                                  class="form-control"
                                  placeholder="Perut"
                                  :class="{
                                    'is-invalid': formValidate.perut,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.genital"
                                  type="text"
                                  class="form-control"
                                  placeholder="Genital"
                                  :class="{
                                    'is-invalid': formValidate.genital,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.anus_dubur"
                                  type="text"
                                  class="form-control"
                                  placeholder="Anus/Dubur"
                                  :class="{
                                    'is-invalid': formValidate.anus_dubur,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.lengan_atas"
                                  type="text"
                                  class="form-control"
                                  placeholder="Lengan Atas"
                                  :class="{
                                    'is-invalid': formValidate.lengan_atas,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.lengan_bawah"
                                  type="text"
                                  class="form-control"
                                  placeholder="Lengan Bawah"
                                  :class="{
                                    'is-invalid': formValidate.lengan_bawah,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.jari_tangan"
                                  type="text"
                                  class="form-control"
                                  placeholder="Jari Tangan"
                                  :class="{
                                    'is-invalid': formValidate.jari_tangan,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.kuku_tangan"
                                  type="text"
                                  class="form-control"
                                  placeholder="Kuku Tangan"
                                  :class="{
                                    'is-invalid': formValidate.kuku_tangan,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.persendian_tangan"
                                  type="text"
                                  class="form-control"
                                  placeholder="Persendian Tangan"
                                  :class="{
                                    'is-invalid':
                                      formValidate.persendian_tangan,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.tungkai_atas"
                                  type="text"
                                  class="form-control"
                                  placeholder="Tungkai Atas"
                                  :class="{
                                    'is-invalid': formValidate.tungkai_atas,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.tungkai_bawah"
                                  type="text"
                                  class="form-control"
                                  placeholder="Tungkai Bawah"
                                  :class="{
                                    'is-invalid': formValidate.tungkai_bawah,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.jari_kaki"
                                  type="text"
                                  class="form-control"
                                  placeholder="Jari Kaki"
                                  :class="{
                                    'is-invalid': formValidate.jari_kaki,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.kuku_kaki"
                                  type="text"
                                  class="form-control"
                                  placeholder="Kuku Kaki"
                                  :class="{
                                    'is-invalid': formValidate.kuku_kaki,
                                  }"
                                />
                              </div>
                              <div class="col-md-3 mb-2">
                                <input
                                  v-model="pemeriksaan_fisik.persendian_kaki"
                                  type="text"
                                  class="form-control"
                                  placeholder="Persendian Kaki"
                                  :class="{
                                    'is-invalid': formValidate.persendian_kaki,
                                  }"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-custom-scrollbar>
        </div>
      </div>
    </div>

    <div class="wrapper clearfix">
      <button type="button" class="btn btn-default" @click="toggleForm(1)">
        Sebelumnya
      </button>
      <button
        v-if="!this.loadingData"
        type="button"
        class="btn btn-teal pull-right"
        @click="saveData(4)"
      >
        <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
        Selanjutnya
      </button>
    </div>
  </div>
</template>

<script>
import PageOptions from "../../../config/PageOptions.vue";
import axios from "axios";
import sipData from "../../../plugins/sipData";
import Loader from "../../../components/loader/Loader.vue";

export default {
  mixins: [sipData],
  props: ["kode_register", "no_rekam_medik", "nama_form"],
  components: {
    Loader,
  },
  data() {
    PageOptions.pageContentFullWidth = true;
    PageOptions.pageContentFullHeight = true;
    return {
      pemeriksaan_fisik: {
        id_pemeriksaan_fisik: "",
        kode_pemeriksaan_fisik: "",
        kode_rekam_medik: "",
        kode_register: "",
        keadaaan_umum: "",
        tingkat_kesadaran: "",
        tinggi_badan: "",
        denyut_jantung: "",
        pernapasan: "",
        sistole: "",
        diastole: "",
        suhu_tubuh: "",
        kepala: "",
        mata: "",
        telinga: "",
        hidung: "",
        rambut: "",
        bibir: "",
        gigi_geligi: "",
        lidah: "",
        langit_langit: "",
        leher: "",
        tenggorokan: "",
        tonsil: "",
        dada: "",
        payudara: "",
        punggung: "",
        perut: "",
        genital: "",
        anus_dubur: "",
        lengan_atas: "",
        lengan_bawah: "",
        jari_tangan: "",
        kuku_tangan: "",
        persendian_tangan: "",
        tungkai_atas: "",
        tungkai_bawah: "",
        jari_kaki: "",
        kuku_kaki: "",
        persendian_kaki: "",
        assesmen_nyeri: "",
        skala_nyeri: "",
        lokasi_nyeri: "",
        penyebab_nyeri: "",
        durasi_nyeri: "",
        frekuensi_nyeri: "",
        risiko_jatuh: "",
      },
      formValidate: [],
      loadingData: false,
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    saveData(param) {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.pemeriksaan_fisik) {
        formData.append(key, this.pemeriksaan_fisik[key]);
      }
      formData.append("kode_rekam_medik", this.no_rekam_medik);
      formData.append("kode_register", this.kode_register);

      axios
        .post("/v1/rekammedik/pemeriksaan_fisik", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.$emit("formCode", param);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      this.loadingData = true;
      axios
        .get("/v1/rekammedik/pemeriksaan_fisik/", {
          params: {
            kode_register: this.kode_register,
          },
        })
        .then((response) => {
          for (let key in response.data.data) {
            this.pemeriksaan_fisik[key] = response.data.data[key];
          }
          this.loadingData = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    toggleForm(param) {
      this.$emit("formCode", param);
    },
  },
};
</script>
<template>
  <div class="vertical-box">
    <div class="wrapper">
      <div class="btn-toolbar">
        <div class="btn-group btn-block m-r-5">
          <a
            href="javascript:;"
            class="btn btn-default btn-sm p-l-auto p-r-auto"
            style="font-weight: bold"
          >
            {{ nama_form }}
          </a>
        </div>
      </div>
    </div>

    <div class="vertical-box-row bg-white">
      <div class="vertical-box-cell">
        <div class="vertical-box-inner-cell">
          <vue-custom-scrollbar class="height-full">
            <div class="container mt-3">
              <div>
                <div class="row">
                  <Loader v-if="this.loadingData"></Loader>
                  <div v-else class="container">
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="nama_tindakan"
                        >Nama Tindakan</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="search.tindakan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.nama_tindakan,
                          }"
                          placeholder="Masukkan Tindakan/Kode ICD 9"
                          @input="handleTindakan()"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15" v-if="showTindakan">
                      <label class="col-form-label col-md-3"></label>
                      <div class="col-md-9">
                        <table style="width: 100%">
                          <tr
                            v-for="icd in rows.icd"
                            v-bind:key="icd.code"
                            @click="setTindakan(icd.code, icd.name_id)"
                          >
                            <td>
                              <a
                                class="btn btn-sm btn-block btn-default"
                                style="text-align: left"
                                href="javascript:;"
                                >{{ icd.code }} : {{ icd.name_id }}</a
                              >
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="petugas_pelaksana_tindakan"
                        >Petugas yang Melaksanakan</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="tindakan.petugas_pelaksana_tindakan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid':
                              formValidate.petugas_pelaksana_tindakan,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="tgl_tindakan"
                        >Tanggal Pelaksanaan Tindakan</label
                      >
                      <div class="col-md-9">
                        <input
                          type="date"
                          v-model="tindakan.tanggal_tindakan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.tanggal_tindakan,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label class="col-md-3 col-form-label push-right"
                        >Waktu Tindakan</label
                      >
                      <div class="col-md-9">
                        <div class="input-group">
                          <input
                            v-model="tindakan.waktu_mulai_tindakan"
                            type="time"
                            class="form-control"
                            :class="{
                              'is-invalid': formValidate.waktu_mulai_tindakan,
                            }"
                          />
                          <span class="input-group-append">
                            <span class="input-group-text">-</span>
                          </span>
                          <input
                            v-model="tindakan.waktu_selesai_tindakan"
                            type="time"
                            class="form-control"
                            :class="{
                              'is-invalid': formValidate.waktu_selesai_tindakan,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="alat_medis_digunakan"
                        >Alat Medis yang Digunakan</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="tindakan.alat_medis_digunakan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.alat_medis_digunakan,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="bmhp"
                        >BMHP</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="tindakan.bmhp"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.bmhp,
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-custom-scrollbar>
        </div>
      </div>
    </div>

    <div class="wrapper clearfix">
      <button type="button" class="btn btn-default" @click="toggleForm(8)">
        Sebelumnya
      </button>
      <button
        v-if="!this.loadingData"
        type="button"
        class="btn btn-teal pull-right"
        @click="saveData(10)"
      >
        <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
        Selanjutnya
      </button>
    </div>
  </div>
</template>

<script>
import PageOptions from "../../../config/PageOptions.vue";
import axios from "axios";
import sipData from "../../../plugins/sipData";
import Loader from "../../../components/loader/Loader.vue";

export default {
  mixins: [sipData],
  props: ["kode_register", "no_rekam_medik", "nama_form"],
  components: {
    Loader,
  },
  data() {
    PageOptions.pageContentFullWidth = true;
    PageOptions.pageContentFullHeight = true;
    return {
      tindakan: {
        id_tindakan: "",
        kode_tindakan: "",
        kode_faskes: "",
        kode_register: "",
        kode_rekam_medik: "",
        nama_tindakan: "",
        kode_icd_tindakan: "",
        petugas_pelaksana_tindakan: "",
        tanggal_tindakan: "",
        waktu_mulai_tindakan: "",
        waktu_selesai_tindakan: "",
        alat_medis_digunakan: "",
        bmhp: "",
      },
      search: {
        tindakan: "",
      },
      rows: {
        icd: [],
        pasien: [],
        gejala: [],
      },
      formValidate: [],
      loadingData: false,
      loading: false,
      timeoutId: null,
      showTindakan: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    saveData(param) {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.tindakan) {
        formData.append(key, this.tindakan[key]);
      }
      formData.append("kode_rekam_medik", this.no_rekam_medik);
      formData.append("kode_register", this.kode_register);

      axios
        .post("/v1/rekammedik/tindakan", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.$emit("formCode", param);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      this.loadingData = true;
      axios
        .get("/v1/rekammedik/tindakan/", {
          params: {
            kode_register: this.kode_register,
          },
        })
        .then((response) => {
          if (response.data.status) {
            for (let key in response.data.data) {
              this.tindakan[key] = response.data.data[key];
            }
            this.tindakan = response.data.data;
            this.search.tindakan =
              response.data.data.kode_icd_tindakan +
              " : " +
              response.data.data.nama_tindakan;
          }
          this.loadingData = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    toggleForm(param) {
      this.$emit("formCode", param);
    },
    handleTindakan(param) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.searchICD(param);
      }, 500);
    },
    searchICD() {
      const formData = new FormData();
      this.showTindakan = true;
      formData.append("search", this.search.tindakan);

      axios
        .post("/v1/icdnine/search/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows.icd = response.data.data || [];
          if (this.rows.icd.length <= 0) {
            this.showTindakan = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setTindakan(kode, tindakan) {
      this.showTindakan = false;
      this.tindakan.kode_icd_tindakan = kode;
      this.tindakan.nama_tindakan = tindakan;
      this.search.tindakan = kode + " : " + tindakan;
      this.rows.icd = [];
    },
  },
};
</script>
<template>
  <div class="vertical-box">
    <div class="wrapper">
      <div class="btn-toolbar">
        <div class="btn-group btn-block m-r-5">
          <a
            href="javascript:;"
            class="btn btn-default btn-sm p-l-auto p-r-auto"
            style="font-weight: bold"
          >
            {{ nama_form }}
          </a>
        </div>
      </div>
    </div>

    <div class="vertical-box-row bg-white">
      <div class="vertical-box-cell">
        <div class="vertical-box-inner-cell">
          <vue-custom-scrollbar class="height-full">
            <div class="container mt-3">
              <div>
                <div class="row">
                  <Loader v-if="this.loadingData"></Loader>
                  <div v-else class="container">
                    <div class="form-group row m-b-15">
                      <label class="col-md-3 col-form-label push-right"
                        >Waktu</label
                      >
                      <div class="col-md-9">
                        <div class="input-group">
                          <span class="input-group-prepend">
                            <span
                              class="input-group-text"
                              style="height: calc(1.5em + 0.875rem + 2px)"
                              >Tanggal</span
                            >
                          </span>
                          <input
                            type="date"
                            v-model="
                              persetujuan_tindakan.tanggal_persetujuan_tindakan
                            "
                            class="form-control col-md-3"
                            :class="{
                              'is-invalid':
                                formValidate.tanggal_persetujuan_tindakan,
                            }"
                          />
                          <span class="input-group-append">
                            <span
                              class="input-group-text"
                              style="height: calc(1.5em + 0.875rem + 2px)"
                              >Jam</span
                            >
                          </span>
                          <input
                            type="time"
                            v-model="
                              persetujuan_tindakan.jam_persetujuan_tindakan
                            "
                            class="form-control col-md-2 m-b-5"
                            :class="{
                              'is-invalid':
                                formValidate.jam_persetujuan_tindakan,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="dokter_pemberi_penjelasan"
                        >Dokter yang Memberi Penjelasan</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="
                            persetujuan_tindakan.dokter_pemberi_penjelasan
                          "
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid':
                              formValidate.dokter_pemberi_penjelasan,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="petugas_pendamping"
                        >Petugas yang Mendampingi</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="persetujuan_tindakan.petugas_pendamping"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.petugas_pendamping,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="keluarga_pasien"
                        >Keluarga Pasien</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="persetujuan_tindakan.keluarga_pasien"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.keluarga_pasien,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="tindakan_dilakukan"
                        >Tindakan yang Dilakukan</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="persetujuan_tindakan.tindakan_dilakukan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.tindakan_dilakukan,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="konsekuensi_tindakan"
                        >Konsekuensi dari Tindakan</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="persetujuan_tindakan.konsekuensi_tindakan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.konsekuensi_tindakan,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label class="col-md-3 col-form-label push-right"
                        >Saksi</label
                      >
                      <div class="col-md-9">
                        <div class="input-group">
                          <span class="input-group-prepend">
                            <span
                              class="input-group-text"
                              style="height: calc(1.5em + 0.875rem + 2px)"
                              >Saksi 1</span
                            >
                          </span>
                          <input
                            v-model="persetujuan_tindakan.saksi_1"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.saksi_1,
                            }"
                          />
                          <span class="input-group-append">
                            <span
                              class="input-group-text"
                              style="height: calc(1.5em + 0.875rem + 2px)"
                              >Saksi 2</span
                            >
                          </span>
                          <input
                            v-model="persetujuan_tindakan.saksi_2"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.saksi_2,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <div class="col-md-3">
                        <label
                          class="col-form-label push-right"
                          for="persetujuan_pasien"
                          >Persetujuan / Penolakan Tindakan</label
                        >
                      </div>
                      <div class="col-md-3">
                        <select
                          v-model="persetujuan_tindakan.persetujuan_pasien"
                          class="form-control"
                          :class="{
                            'is-invalid': formValidate.persetujuan_pasien,
                          }"
                        >
                          <option value="Ya">Ya</option>
                          <option value="Tidak">Tidak</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-custom-scrollbar>
        </div>
      </div>
    </div>

    <div class="wrapper clearfix">
      <button type="button" class="btn btn-default" @click="toggleForm(7)">
        Sebelumnya
      </button>
      <button
        v-if="!this.loadingData"
        type="button"
        class="btn btn-teal pull-right"
        @click="saveData(9)"
      >
        <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
        Selanjutnya
      </button>
    </div>
  </div>
</template>

<script>
import PageOptions from "../../../config/PageOptions.vue";
import axios from "axios";
import sipData from "../../../plugins/sipData";
import Loader from "../../../components/loader/Loader.vue";

export default {
  mixins: [sipData],
  props: ["kode_register", "no_rekam_medik", "nama_form"],
  components: {
    Loader,
  },
  data() {
    PageOptions.pageContentFullWidth = true;
    PageOptions.pageContentFullHeight = true;
    return {
      persetujuan_tindakan: {
        id_persetujuan_tindakan: "",
        kode_persetujuan_tindakan: "",
        kode_faskes: "",
        kode_register: "",
        kode_rekam_medik: "",
        tanggal_persetujuan_tindakan: "",
        jam_persetujuan_tindakan: "",
        dokter_pemberi_penjelasan: "",
        petugas_pendamping: "",
        keluarga_pasien: "",
        tindakan_dilakukan: "",
        konsekuensi_tindakan: "",
        persetujuan_pasien: "",
        saksi_1: "",
        saksi_2: "",
      },
      formValidate: [],
      loadingData: false,
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    saveData(param) {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.persetujuan_tindakan) {
        formData.append(key, this.persetujuan_tindakan[key]);
      }
      formData.append("kode_rekam_medik", this.no_rekam_medik);
      formData.append("kode_register", this.kode_register);

      axios
        .post("/v1/rekammedik/persetujuan_tindakan", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.$emit("formCode", param);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      this.loadingData = true;
      axios
        .get("/v1/rekammedik/persetujuan_tindakan/", {
          params: {
            kode_register: this.kode_register,
          },
        })
        .then((response) => {
          for (let key in response.data.data) {
            this.persetujuan_tindakan[key] = response.data.data[key];
          }
          this.loadingData = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    toggleForm(param) {
      this.$emit("formCode", param);
    },
  },
};
</script>
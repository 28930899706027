<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-teal text-light">
            <h3 class="panel-title bold">Form Pasien</h3>
          </div>
          <form @submit.prevent="postData" enctype="multipart/form-data">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="nik">NIK : </label>
                    <input
                      v-model="form.nik"
                      type="number"
                      class="form-control"
                      name="nik"
                      placeholder="NIK"
                      :class="{
                        'is-invalid': formValidate.nik,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="nama">Nama : </label>
                    <input
                      v-model="form.nama"
                      type="text"
                      class="form-control"
                      name="nama"
                      placeholder="Nama"
                      :class="{
                        'is-invalid': formValidate.nama,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="no_kk">No KK : </label>
                    <input
                      v-model="form.no_kk"
                      type="number"
                      class="form-control"
                      name="no_kk"
                      placeholder="No. Kartu Keluarga"
                      :class="{
                        'is-invalid': formValidate.no_kk,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="nama_ibu_kandung">Nama Ibu Kandung : </label>
                    <input
                      v-model="form.nama_ibu_kandung"
                      type="nama"
                      class="form-control"
                      name="nama_ibu_kandung"
                      placeholder="Nama Ibu Kandung"
                      :class="{
                        'is-invalid': formValidate.nama_ibu_kandung,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="nama_kk">Nama KK : </label>
                    <input
                      v-model="form.nama_kk"
                      type="nama"
                      class="form-control"
                      name="nama_kk"
                      placeholder="Nama Kepala Keluarga"
                      :class="{
                        'is-invalid': formValidate.nama_kk,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="status_kk">Status Dalam Keluarga : </label>
                    <select
                      v-model="form.status_kk"
                      class="form-control"
                      name="status_kk"
                      :class="{
                        'is-invalid': formValidate.status_kk,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option value="Suami">Suami</option>
                      <option value="Istri">Istri</option>
                      <option value="Anak">Anak</option>
                      <option value="Lainnya">Lainnya</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="status_kawin">Status Kawin : </label>
                    <select
                      v-model="form.status_kawin"
                      class="form-control"
                      name="status_kawin"
                      :class="{
                        'is-invalid': formValidate.status_kawin,
                      }"
                    >
                      <option value=""></option>
                      <option value="Kawin">Kawin</option>
                      <option value="Belum Kawin">Belum Kawin</option>
                      <option value="Cerai Hidup">Cerai Hidup</option>
                      <option value="Cerai Mati">Cerai Mati</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="tempat_lahir">Tempat Lahir : </label>
                    <input
                      v-model="form.tempat_lahir"
                      type="text"
                      class="form-control"
                      name="tempat_lahir"
                      placeholder="Tempat Lahir"
                      :class="{
                        'is-invalid': formValidate.tempat_lahir,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="tgl_lahir">Tanggal Lahir : </label>
                    <input
                      v-model="form.tgl_lahir"
                      type="date"
                      class="form-control"
                      name="tgl_lahir"
                      placeholder="Tgl Lahir"
                      :class="{
                        'is-invalid': formValidate.tgl_lahir,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="jenis_kelamin">Jenis Kelamin : </label>
                    <select
                      v-model="form.jenis_kelamin"
                      class="form-control"
                      name="jenis_kelamin"
                      :class="{
                        'is-invalid': formValidate.jenis_kelamin,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option value="Laki-Laki">Laki-Laki</option>
                      <option value="Perempuan">Perempuan</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="jenis_jaminan">Jenis Jaminan : </label>

                    <select
                      v-model="form.jenis_jaminan"
                      class="form-control"
                      name="jenis_jaminan"
                      :class="{
                        'is-invalid': formValidate.jenis_jaminan,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option value="Umum">Umum</option>
                      <option value="JKN Non PBI">JKN Non PBI</option>
                      <option value="JKN PBI">JKN PBI</option>
                      <option value="Mandiri">Mandiri</option>
                    </select>
                  </div>
                </div>

                <div
                  class="col-md-3"
                  v-if="
                    form.jenis_jaminan != 'Umum' && form.jenis_jaminan != ''
                  "
                >
                  <div class="form-group">
                    <label for="no_kartu_jaminan">No Kartu Jaminan : </label>
                    <input
                      v-model="form.no_kartu_jaminan"
                      type="number"
                      class="form-control"
                      name="no_kartu_jaminan"
                      placeholder="No Kartu, Kosongkan Jika Tidak Ada"
                    />
                  </div>
                </div>

                <div
                  class="col-md-3"
                  v-if="
                    form.jenis_jaminan != 'Umum' && form.jenis_jaminan != ''
                  "
                >
                  <div class="form-group">
                    <label for="faskes">Faskes : </label>
                    <input
                      v-model="form.faskes"
                      type="text"
                      class="form-control"
                      name="faskes"
                      placeholder="Nama Faskes, Kosongkan Jika Tidak Ada"
                      list="listFaskes"
                    />
                    <datalist id="listFaskes">
                      <option
                        v-for="faskes in opsiFaskes"
                        :key="faskes.kode_faskes"
                        :value="faskes.nama_faskes"
                      >
                        {{ faskes.nama_faskes }}
                      </option>
                    </datalist>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="agama">Agama : </label>
                    <select
                      v-model="form.agama"
                      class="form-control"
                      name="agama"
                      placeholder="Agama"
                      :class="{
                        'is-invalid': formValidate.agama,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option value="Islam">Islam</option>
                      <option value="Katolik">Katolik</option>
                      <option value="Protestan">Protestan</option>
                      <option value="Hindu">Hindu</option>
                      <option value="Budha">Budha</option>
                      <option value="Konghuchu">Konghuchu</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="no_telpon">No. Telpon/HP : </label>
                    <input
                      v-model="form.no_telpon"
                      type="number"
                      class="form-control"
                      name="no_telpon"
                      placeholder="No. Telpon/HP"
                      :class="{
                        'is-invalid': formValidate.no_telpon,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="pekerjaan">Pekerjaan : </label>
                    <input
                      v-model="form.pekerjaan"
                      type="text"
                      class="form-control"
                      name="pekerjaan"
                      placeholder="Pekerjaan"
                      :class="{
                        'is-invalid': formValidate.pekerjaan,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="golongan_darah">Golongan Darah : </label>
                    <select
                      v-model="form.golongan_darah"
                      class="form-control"
                      name="golongan_darah"
                      :class="{
                        'is-invalid': formValidate.golongan_darah,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option value="AB">AB</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="O">O</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="kewarganegaraan">Kewarganegaraan : </label>
                    <select
                      v-model="form.kewarganegaraan"
                      class="form-control"
                      name="kewarganegaraan"
                      placeholder="Kewarganegaraan"
                      :class="{
                        'is-invalid': formValidate.kewarganegaraan,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option value="WNI">Warga Negara Indonesia</option>
                      <option value="WNA">Warga Negara Asing</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3" v-if="form.kewarganegaraan == 'WNA'">
                  <div class="form-group">
                    <label for="no_paspor">Nomor Paspor : </label>
                    <input
                      v-model="form.no_paspor"
                      type="text"
                      class="form-control"
                      name="no_paspor"
                      placeholder="Nomor Paspor"
                      :class="{
                        'is-invalid': formValidate.no_paspor,
                      }"
                    />
                  </div>
                </div>
              </div>

              <div class="row" style="margin-top: 15px">
                <div class="col-md-12">
                  <span
                    style="
                      background-color: #009688;
                      border-radius: 5px 5px 0px 0px;
                      padding: 10px;
                      color: #fff;
                    "
                  >
                    Alamat Sesuai KTP
                  </span>
                  <hr style="margin-top: 7px; border-top: solid 2px #009688" />
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="alamat_ktp">Alamat Tempat Tinggal : </label>
                    <input
                      v-model="form.alamat_ktp"
                      type="text"
                      class="form-control"
                      name="alamat_ktp"
                      placeholder="Masukkan Nama Jalan"
                      :class="{
                        'is-invalid': formValidate.alamat_ktp,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="provinsi_ktp">Provinsi : </label>
                    <select
                      v-model="form.provinsi_ktp"
                      class="form-control default-select2"
                      name="provinsi_ktp"
                      v-on:change="getKabupaten($event)"
                      :class="{
                        'is-invalid': formValidate.provinsi_ktp,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option
                        v-for="provinsi in opsiProvinsi"
                        :key="provinsi.nama_provinsi"
                        :value="provinsi.nama_provinsi"
                      >
                        {{ provinsi.nama_provinsi }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="kabupaten_ktp">Kabupaten/Kota : </label>
                    <select
                      v-model="form.kabupaten_ktp"
                      name="kabupaten_ktp"
                      class="form-control m-b-5"
                      v-on:change="getKecamatan($event)"
                      :class="{
                        'is-invalid': formValidate.kabupaten_ktp,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option
                        v-for="kabupaten in opsiKabupaten"
                        :key="kabupaten.nama_kabupaten"
                        :value="kabupaten.nama_kabupaten"
                      >
                        {{ kabupaten.nama_kabupaten }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="kecamatan_ktp">Kecamatan : </label>
                    <select
                      v-model="form.kecamatan_ktp"
                      name="kecamatan_ktp"
                      class="form-control m-b-5"
                      :class="{
                        'is-invalid': formValidate.kecamatan_ktp,
                      }"
                      v-on:change="getKelurahan($event)"
                    >
                      <option value="">Pilih</option>
                      <option
                        v-for="kecamatan in opsiKecamatan"
                        :key="kecamatan.nama_kecamatan"
                        :value="kecamatan.nama_kecamatan"
                      >
                        {{ kecamatan.nama_kecamatan }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="kelurahan_ktp">Kelurahan : </label>
                    <select
                      v-model="form.kelurahan_ktp"
                      name="kelurahan_ktp"
                      class="form-control m-b-5"
                      :class="{
                        'is-invalid': formValidate.kelurahan_ktp,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option
                        v-for="kelurahan in opsiKelurahan"
                        :key="kelurahan.nama_kelurahan"
                        :value="kelurahan.nama_kelurahan"
                      >
                        {{ kelurahan.nama_kelurahan }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="rw_ktp">RW : </label>
                    <input
                      v-model="form.rw_ktp"
                      type="text"
                      class="form-control"
                      name="rw_ktp"
                      placeholder="RW"
                      :class="{
                        'is-invalid': formValidate.rw_ktp,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="rt_ktp">RT : </label>
                    <input
                      v-model="form.rt_ktp"
                      type="text"
                      class="form-control"
                      name="rt_ktp"
                      placeholder="RT"
                      :class="{
                        'is-invalid': formValidate.rt_ktp,
                      }"
                    />
                  </div>
                </div>
              </div>

              <div class="row" style="margin-top: 15px">
                <div class="col-md-12">
                  <span
                    style="
                      background-color: #009688;
                      border-radius: 5px 5px 0px 0px;
                      padding: 10px;
                      color: #fff;
                    "
                  >
                    Alamat Domisili
                  </span>
                  <hr style="margin-top: 7px; border-top: solid 2px #009688" />
                </div>
                <div class="col-md-12 mb-3">
                  <div class="checkbox checkbox-css">
                    <input
                      type="checkbox"
                      id="sesuai_ktp"
                      v-model="form.sesuai_ktp"
                      v-on:change="setDomisi($event)"
                    />
                    <label for="sesuai_ktp">Domisili sesuai dengan KTP</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="alamat_domisili">Alamat Domisili : </label>
                    <input
                      v-model="form.alamat_domisili"
                      type="text"
                      class="form-control"
                      name="alamat_domisili"
                      placeholder="Masukkan Nama Jalan"
                      :class="{
                        'is-invalid': formValidate.alamat_domisili,
                      }"
                      :readonly="
                        form.sesuai_ktp === true || form.sesuai_ktp === 'true'
                      "
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="provinsi_domisili">Provinsi : </label>
                    <input
                      type="text"
                      v-model="form.provinsi_domisili"
                      class="form-control"
                      readonly
                      v-if="
                        form.sesuai_ktp === true || form.sesuai_ktp === 'true'
                      "
                    />
                    <select
                      v-else
                      v-model="form.provinsi_domisili"
                      class="form-control default-select2"
                      name="provinsi_domisili"
                      v-on:change="getKabupatenDomisili($event)"
                      :class="{
                        'is-invalid': formValidate.provinsi_domisili,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option
                        v-for="provinsi in opsiProvinsi"
                        :key="provinsi.nama_provinsi"
                        :value="provinsi.nama_provinsi"
                      >
                        {{ provinsi.nama_provinsi }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="kabupaten_domisili">Kabupaten/Kota : </label>
                    <input
                      type="text"
                      v-model="form.kabupaten_domisili"
                      class="form-control"
                      readonly
                      v-if="
                        form.sesuai_ktp === true || form.sesuai_ktp === 'true'
                      "
                    />
                    <select
                      v-else
                      v-model="form.kabupaten_domisili"
                      name="kabupaten_domisili"
                      class="form-control m-b-5"
                      v-on:change="getKecamatanDomisili($event)"
                      :class="{
                        'is-invalid': formValidate.kabupaten_domisili,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option
                        v-for="kabupaten in opsiKabupatenDomisili"
                        :key="kabupaten.nama_kabupaten"
                        :value="kabupaten.nama_kabupaten"
                      >
                        {{ kabupaten.nama_kabupaten }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="kecamatan_domisili">Kecamatan : </label>
                    <input
                      type="text"
                      v-model="form.kecamatan_domisili"
                      class="form-control"
                      readonly
                      v-if="
                        form.sesuai_ktp === true || form.sesuai_ktp === 'true'
                      "
                    />
                    <select
                      v-else
                      v-model="form.kecamatan_domisili"
                      name="kecamatan_domisili"
                      class="form-control m-b-5"
                      :class="{
                        'is-invalid': formValidate.kecamatan_domisili,
                      }"
                      v-on:change="getKelurahanDomisili($event)"
                    >
                      <option value="">Pilih</option>
                      <option
                        v-for="kecamatan in opsiKecamatanDomisili"
                        :key="kecamatan.nama_kecamatan"
                        :value="kecamatan.nama_kecamatan"
                      >
                        {{ kecamatan.nama_kecamatan }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="kelurahan_domisili">Kelurahan : </label>
                    <input
                      type="text"
                      v-model="form.kelurahan_domisili"
                      class="form-control"
                      readonly
                      v-if="
                        form.sesuai_ktp === true || form.sesuai_ktp === 'true'
                      "
                    />
                    <select
                      v-else
                      v-model="form.kelurahan_domisili"
                      name="kelurahan_domisili"
                      class="form-control m-b-5"
                      :class="{
                        'is-invalid': formValidate.kelurahan_domisili,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option
                        v-for="kelurahan in opsiKelurahanDomisili"
                        :key="kelurahan.nama_kelurahan"
                        :value="kelurahan.nama_kelurahan"
                      >
                        {{ kelurahan.nama_kelurahan }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="rw_domisili">RW : </label>
                    <input
                      v-model="form.rw_domisili"
                      type="text"
                      class="form-control"
                      name="rw_domisili"
                      placeholder="RW"
                      :class="{
                        'is-invalid': formValidate.rw_domisili,
                      }"
                      :readonly="
                        form.sesuai_ktp === true || form.sesuai_ktp === 'true'
                      "
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="rt_domisili">RT : </label>
                    <input
                      v-model="form.rt_domisili"
                      type="text"
                      class="form-control"
                      name="rt_domisili"
                      placeholder="RT"
                      :class="{
                        'is-invalid': formValidate.rt_domisili,
                      }"
                      :readonly="
                        form.sesuai_ktp === true || form.sesuai_ktp === 'true'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-footer">
              <a
                href="javascript:window.history.go(-1);"
                class="btn btn-default"
                >Batal</a
              >
              <button
                type="button"
                class="btn btn-teal pull-right"
                @click="postData()"
                :disabled="this.loading"
              >
                <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
                {{ $route.params.id ? "Update" : "Submit" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      form: {
        id_iup: "",
        kode_pasien: "",
        kode_faskes: "",
        nama: "",
        no_rm: "",
        kewarganegaraan: "",
        nik: "",
        no_paspor: "",
        nama_ibu_kandung: "",
        tempat_lahir: "",
        tgl_lahir: "",
        jenis_kelamin: "",
        jenis_jaminan: "",
        no_kartu_jaminan: "",
        faskes: "",
        agama: "",
        suku: "",
        bahasa_dikuasai: "",
        alamat_ktp: "",
        rt_ktp: "",
        rw_ktp: "",
        kelurahan_ktp: "",
        kecamatan_ktp: "",
        kabupaten_ktp: "",
        provinsi_ktp: "",
        kode_pos_ktp: "",
        negara_ktp: "",
        alamat_domisili: "",
        rt_domisili: "",
        rw_domisili: "",
        kelurahan_domisili: "",
        kecamatan_domisili: "",
        kabupaten_domisili: "",
        provinsi_domisili: "",
        kode_pos_domisili: "",
        negara_domisili: "",
        no_telpon: "",
        pendidikan: "",
        pekerjaan: "",
        status_kawin: "",
        no_kk: "",
        nama_kk: "",
        status_kk: "",
        golongan_darah: "",
        sesuai_ktp: false,
      },
      link: "/v1/identitas_umum_pasien/create",
      opsiProvinsi: [],
      opsiKabupaten: [],
      opsiKecamatan: [],
      opsiKelurahan: [],
      opsiKabupatenDomisili: [],
      opsiKecamatanDomisili: [],
      opsiKelurahanDomisili: [],
      opsiFaskes: [],
      formValidate: [],
      loading: false,
      showUserAccess: true,
    };
  },
  mounted() {
    // this.getKabupaten();
    this.getProvinsi();
    this.getFaskes();
    const id = this.$route.params.id;
    if (id) {
      this.link = "/v1/identitas_umum_pasien/update";
      this.form.id_iup = id;
      this.fetchData();
    } else {
      this.form.id = "";
    }
  },
  methods: {
    postData() {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/v1/identitas_umum_pasien/detail/" + this.form.id_iup)
        .then((response) => {
          console.log(response.data.data);
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
          this.getOption();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getProvinsi() {
      axios
        .get("/v1/daerah/provinsi")
        .then((response) => {
          this.opsiProvinsi = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKabupaten(event) {
      this.form.provinsi_ktp = event.target.value;
      axios
        .get("/v1/daerah/kabupaten/" + event.target.value)
        .then((response) => {
          this.opsiKabupaten = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKecamatan(event) {
      this.form.kabupaten_ktp = event.target.value;
      axios
        .get("/v1/daerah/kecamatan/" + event.target.value)
        .then((response) => {
          this.opsiKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKelurahan(event) {
      this.form.kecamatan_ktp = event.target.value;
      axios
        .get("/v1/daerah/kelurahan/" + event.target.value)
        .then((response) => {
          this.opsiKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKabupatenDomisili(event) {
      this.form.provinsi_domisili = event.target.value;
      axios
        .get("/v1/daerah/kabupaten/" + event.target.value)
        .then((response) => {
          this.opsiKabupatenDomisili = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKecamatanDomisili(event) {
      this.form.kabupaten_domisili = event.target.value;
      axios
        .get("/v1/daerah/kecamatan/" + event.target.value)
        .then((response) => {
          this.opsiKecamatanDomisili = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKelurahanDomisili(event) {
      this.form.kecamatan_domisili = event.target.value;
      axios
        .get("/v1/daerah/kelurahan/" + event.target.value)
        .then((response) => {
          this.opsiKelurahanDomisili = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getOption() {
      if (this.form.provinsi_ktp) {
        axios
          .get("/v1/daerah/kabupaten/" + this.form.provinsi_ktp)
          .then((response) => {
            this.opsiKabupaten = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.provinsi_domisili) {
        axios
          .get("/v1/daerah/kabupaten/" + this.form.provinsi_domisili)
          .then((response) => {
            this.opsiKabupatenDomisili = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.kabupaten_ktp) {
        axios
          .get("/v1/daerah/kecamatan/" + this.form.kabupaten_ktp)
          .then((response) => {
            this.opsiKecamatan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.kabupaten_domisili) {
        axios
          .get("/v1/daerah/kecamatan/" + this.form.kabupaten_domisili)
          .then((response) => {
            this.opsiKecamatanDomisili = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.kecamatan_ktp) {
        axios
          .get("/v1/daerah/kelurahan/" + this.form.kecamatan_ktp)
          .then((response) => {
            this.opsiKelurahan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.kecamatan_domisili) {
        axios
          .get("/v1/daerah/kelurahan/" + this.form.kecamatan_domisili)
          .then((response) => {
            this.opsiKelurahanDomisili = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    getFaskes() {
      const filter = "01";
      axios
        .get("/v1/faskes/" + filter)
        .then((response) => {
          this.opsiFaskes = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setDomisi(event) {
      if (event.target.checked) {
        this.form.sesuai_ktp = event.target.checked;
        this.form.alamat_domisili = this.form.alamat_ktp;
        this.form.provinsi_domisili = this.form.provinsi_ktp;
        this.form.kabupaten_domisili = this.form.kabupaten_ktp;
        this.form.kecamatan_domisili = this.form.kecamatan_ktp;
        this.form.kelurahan_domisili = this.form.kelurahan_ktp;
        this.form.rw_domisili = this.form.rw_ktp;
        this.form.rt_domisili = this.form.rt_ktp;
      } else {
        this.form.sesuai_ktp = "";
        this.form.alamat_domisili = "";
        this.form.provinsi_domisili = "";
        this.form.kabupaten_domisili = "";
        this.form.kecamatan_domisili = "";
        this.form.kelurahan_domisili = "";
        this.form.rw_domisili = "";
        this.form.rt_domisili = "";
      }
    },
  },
};
</script>

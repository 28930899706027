<template>
  <div class="vertical-box with-grid inbox">
    <div class="vertical-box-column width-200 hidden-xs">
      <div class="vertical-box">
        <div class="wrapper">
          <div class="d-flex align-items-center justify-content-center">
            <b-button
              v-b-toggle.emailNav
              variant="inverse"
              class="btn-sm mr-auto d-block d-lg-none"
            >
              <i class="fa fa-ellipsis-h"></i>
            </b-button>
            <a
              href="javascript:;"
              class="btn btn-default btn-sm p-l-20 p-r-20"
              style="font-weight: bold"
            >
              Informasi Pasien
            </a>
          </div>
        </div>
        <b-collapse
          class="vertical-box-row collapse d-lg-table-row"
          id="emailNav"
        >
          <div class="vertical-box-cell">
            <div class="vertical-box-inner-cell">
              <div data-scrollbar="true" data-height="100%">
                <div class="wrapper p-0">
                  <div class="nav-title f-s-14">
                    <div>
                      <b>No. Rekam Medik</b>
                      <p v-if="detail.no_rekam_medik">
                        {{ detail.no_rekam_medik }}
                      </p>
                      <div v-else>
                        <p class="skeleton-loader"></p>
                      </div>
                    </div>
                    <div>
                      <b>Nama</b>
                      <p v-if="detail.nama">
                        {{ detail.nama }}
                      </p>
                      <div v-else>
                        <p class="skeleton-loader"></p>
                      </div>
                    </div>
                    <div>
                      <b>Tanggal</b>
                      <p v-if="detail.tanggal">
                        {{ formatIndo(detail.tanggal) }}
                      </p>
                      <div v-else>
                        <p class="skeleton-loader"></p>
                      </div>
                    </div>
                    <button
                      class="btn btn-sm btn-block btn-info"
                      @click.prevent="showHistory"
                    >
                      <i class="fa fa-history"></i> Riwayat Pengobatan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>

    <div class="vertical-box-column">
      <Anamnesis
        v-if="
          openForm == 1 &&
          this.detail.kode_register &&
          this.detail.no_rekam_medik
        "
        :kode_register="detail.kode_register"
        :no_rekam_medik="detail.no_rekam_medik"
        :nama_form="namaForm"
        @formCode="handleForm"
      ></Anamnesis>
      <PemeriksaanFisik
        v-if="openForm == 2"
        :kode_register="detail.kode_register"
        :no_rekam_medik="detail.no_rekam_medik"
        :nama_form="namaForm"
        @formCode="handleForm"
      ></PemeriksaanFisik>
      <PemeriksaanPsikologis
        v-if="openForm == 4"
        :kode_register="detail.kode_register"
        :no_rekam_medik="detail.no_rekam_medik"
        :nama_form="namaForm"
        @formCode="handleForm"
      ></PemeriksaanPsikologis>
      <PemeriksaanSpesialistik
        v-if="openForm == 5"
        :kode_register="detail.kode_register"
        :no_rekam_medik="detail.no_rekam_medik"
        :nama_form="namaForm"
        @formCode="handleForm"
      ></PemeriksaanSpesialistik>
      <PemeriksaanPenunjang
        v-if="openForm == 6"
        :kode_register="detail.kode_register"
        :no_rekam_medik="detail.no_rekam_medik"
        :nama_form="namaForm"
        @formCode="handleForm"
      ></PemeriksaanPenunjang>
      <Diagnosis
        v-if="openForm == 7"
        :kode_register="detail.kode_register"
        :no_rekam_medik="detail.no_rekam_medik"
        :nama_form="namaForm"
        @formCode="handleForm"
      ></Diagnosis>
      <PersetujuanTindakan
        v-if="openForm == 8"
        :kode_register="detail.kode_register"
        :no_rekam_medik="detail.no_rekam_medik"
        :nama_form="namaForm"
        @formCode="handleForm"
      ></PersetujuanTindakan>
      <Tindakan
        v-if="openForm == 9"
        :kode_register="detail.kode_register"
        :no_rekam_medik="detail.no_rekam_medik"
        :nama_form="namaForm"
        @formCode="handleForm"
      ></Tindakan>
      <Peresepan
        v-if="openForm == 10"
        :kode_register="detail.kode_register"
        :no_rekam_medik="detail.no_rekam_medik"
        :nama_form="namaForm"
        @formCode="handleForm"
      ></Peresepan>
    </div>

    <b-modal size="lg" v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Riwayat Pengobatan</h5>
        <button
          type="button"
          @click="closeModal"
          class="btn btn-danger btn-sm btn-icon btn-circle"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
      </template>
      <div>
        <div class="row">
          <div style="height: 70vh; width: 100%; overflow: scroll">
            <div class="col-md-12">
              <div
                v-for="a in riwayatPengobatan"
                v-bind:key="a.id_antrian"
                class="note bg-grey-transparent-2"
              >
                <div class="note-content">
                  <h4>
                    <b>{{ a.nama_faskes }}</b>
                  </h4>
                  <p>{{ formatIndo(a.tanggal) }}</p>
                  <p>Keluhan : {{ a.keluhan_utama }}</p>
                  <p>Riwayat Alergi : {{ a.riwayat_alergi }}</p>
                  <p>Riwayat Penyakit : {{ a.riwayat_penyakit }}</p>
                  <p>Diagnosa : {{ a.diagnosis_primer }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import axios from "axios";
import moment from "moment";
import sipData from "../../plugins/sipData";
import Anamnesis from "./form/Anamnesis.vue";
import PemeriksaanFisik from "./form/PemeriksaanFisik.vue";
import PemeriksaanPsikologis from "./form/PemeriksaanPsikologis.vue";
import PemeriksaanSpesialistik from "./form/PemeriksaanSpesialistik.vue";
import PemeriksaanPenunjang from "./form/PemeriksaanPenunjang.vue";
import Diagnosis from "./form/Diagnosis.vue";
import PersetujuanTindakan from "./form/PersetujuanTindakan.vue";
import Tindakan from "./form/Tindakan.vue";
import Peresepan from "./form/Peresepan.vue";

export default {
  mixins: [sipData],
  components: {
    Anamnesis,
    PemeriksaanFisik,
    PemeriksaanPsikologis,
    PemeriksaanSpesialistik,
    PemeriksaanPenunjang,
    Diagnosis,
    PersetujuanTindakan,
    Tindakan,
    Peresepan,
  },
  data() {
    PageOptions.pageContentFullWidth = true;
    PageOptions.pageContentFullHeight = true;
    return {
      openForm: 0,
      namaForm: "Anamnesis",
      kode_antrian: "",
      detail: {
        nama: "",
        nik: "",
        kode_register: "",
        kode_faskes: "",
        no_rekam_medik: "",
        tanggal: "",
        jam: "",
      },
      riwayatPengobatan: [],
      loading: false,
      modalVisible: false,
      conditionMet: false,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.kode_antrian = id;
      this.fetchData();
      this.setAksi(id, "Proses");
    } else {
      this.kode_antrian = "";
    }
  },
  methods: {
    fetchData() {
      this.loadingData = true;
      axios
        .get("/v1/antrian/detail/" + this.kode_antrian)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          if (this.detail.kode_register) {
            this.openForm = 1;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setAksi(id, param) {
      axios
        .get("/v1/antrian/proses/", {
          params: {
            id_antrian: id,
            keterangan: param,
          },
        })
        .then((response) => {
          console.log(response.data.status);
          if (param != "Proses") {
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    handleForm(data) {
      this.openForm = data;
      console.log(this.openForm);
      if (data === 1) {
        this.namaForm = "Anamnesis";
      } else if (data === 2) {
        this.namaForm = "Pemeriksaan Fisik";
      } else if (data === 4) {
        this.namaForm = "Pemeriksaan Psikologis, Sosial Ekonomi, Spiritual";
      } else if (data === 5) {
        this.namaForm = "Pemeriksaan Spesialistik";
      } else if (data === 6) {
        this.namaForm = "Pemeriksaan Penunjang";
      } else if (data === 7) {
        this.namaForm = "Diagnosis";
      } else if (data === 8) {
        this.namaForm =
          "Persetujuan Tindakan / Penolakan Tindakan (Informed Consent)";
      } else if (data === 9) {
        this.namaForm = "Tindakan";
      } else if (data === 10) {
        this.namaForm = "Peresepan";
      }
    },
    formatIndo(dateTime) {
      return moment(dateTime).format("DD MMMM YYYY");
    },
    showHistory() {
      this.modalVisible = true;
      this.conditionMet = false;
      this.loadingData = true;
      axios
        .get("/v1/riwayat_pengobatan/" + this.detail.no_rekam_medik, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.riwayatPengobatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.skeleton-loader {
  width: 100%;
  height: 20px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>
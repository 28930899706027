<template>
  <div class="vertical-box">
    <div class="wrapper">
      <div class="btn-toolbar">
        <div class="btn-group btn-block m-r-5">
          <a
            href="javascript:;"
            class="btn btn-default btn-sm p-l-auto p-r-auto"
            style="font-weight: bold"
          >
            {{ nama_form }}
          </a>
        </div>
      </div>
    </div>

    <div class="vertical-box-row bg-white">
      <div class="vertical-box-cell">
        <div class="vertical-box-inner-cell">
          <vue-custom-scrollbar class="height-full">
            <div class="container mt-3">
              <div>
                <div class="row">
                  <Loader v-if="this.loadingData"></Loader>
                  <div v-else class="container">
                    <div class="form-group">
                      <table class="table table-bordered">
                        <tr>
                          <th colspan="4">Obat</th>
                        </tr>
                        <tr>
                          <th class="text-center align-middle">Nama Obat</th>
                          <th
                            class="text-center align-middle"
                            style="width: 150px"
                          >
                            Bentuk Sediaan
                          </th>
                          <th
                            class="text-center align-middle"
                            style="width: 200px"
                          >
                            Jumlah Obat
                          </th>
                          <th
                            class="text-center align-middle"
                            style="width: 200px"
                          >
                            Dosis
                          </th>
                          <th
                            class="text-center align-middle"
                            style="width: 200px"
                          >
                            Frekuensi/Interval
                          </th>
                          <th
                            class="text-center align-middle"
                            style="width: 50px"
                          >
                            <a
                              v-if="userLevel == 'admin_ruang'"
                              href="#"
                              class="btn btn-sm btn-info"
                              role="button"
                              @click.prevent="openModal"
                            >
                              <i class="fa fa-plus"></i>
                            </a>
                          </th>
                        </tr>
                        <tr
                          v-for="obat_peresepan in riwayat_obat"
                          :key="obat_peresepan.id_obat_peresepan"
                        >
                          <td>{{ obat_peresepan.nama_obat }}</td>
                          <td class="text-center">
                            {{ obat_peresepan.bentuk_sediaan }}
                          </td>
                          <td class="text-center">
                            {{ obat_peresepan.jumlah_obat }}
                          </td>
                          <td class="text-center">
                            {{ obat_peresepan.dosis_obat }}
                          </td>
                          <td class="text-center">
                            {{ obat_peresepan.frekuensi_interval }}
                          </td>
                          <td class="text-center align-middle">
                            <button
                              v-if="userLevel == 'admin_ruang'"
                              @click="
                                deleteResepObat(
                                  obat_peresepan.id_obat_peresepan
                                )
                              "
                              :class="{
                                'btn btn-sm btn-danger': true,
                                loading:
                                  loadingDelete[
                                    obat_peresepan.id_obat_peresepan
                                  ],
                              }"
                            >
                              <i
                                v-if="
                                  loadingDelete[
                                    obat_peresepan.id_obat_peresepan
                                  ]
                                "
                                class="fas fa-circle-notch fa-spin"
                              ></i>
                              <i v-else class="fa fa-times"></i>
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="form-group row m-b-15">
                      <label class="col-md-3 col-form-label push-right"
                        >Catatan Resep</label
                      >
                      <div class="col-md-9">
                        <textarea
                          v-if="userLevel == 'admin_ruang'"
                          v-model="peresepan.catatan_resep"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.catatan_resep,
                          }"
                        ></textarea>
                        <label class="col-form-label" v-else
                          >: {{ peresepan.catatan_resep }}</label
                        >
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label class="col-md-3 col-form-label push-right"
                        >Dokter Penulis Resep</label
                      >
                      <div class="col-md-9">
                        <input
                          v-if="userLevel == 'admin_ruang'"
                          type="text"
                          v-model="peresepan.dokter_penulis_resep"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.dokter_penulis_resep,
                          }"
                        />
                        <label class="col-form-label" v-else
                          >: {{ peresepan.dokter_penulis_resep }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-custom-scrollbar>
        </div>
      </div>
    </div>

    <div class="wrapper clearfix">
      <a
        v-if="userLevel == 'admin_apotik'"
        href="javascript:window.history.go(-1);"
        class="btn btn-default"
        >Kembali</a
      >
      <button
        v-else
        type="button"
        class="btn btn-default"
        @click="toggleForm(9)"
      >
        Sebelumnya
      </button>

      <button
        v-if="!this.loadingData"
        type="button"
        class="btn btn-teal pull-right"
        @click="saveData()"
      >
        <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
        Selesai
      </button>
    </div>

    <b-modal size="lg" v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title"></h5>
        <button
          type="button"
          @click="closeModal"
          class="btn btn-danger btn-sm btn-icon btn-circle"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <input
                  v-model="obat.nama_obat"
                  class="form-control m-b-5"
                  placeholder="Nama Obat"
                  :class="{
                    'is-invalid': formValidate.nama_obat,
                  }"
                />
              </div>
              <div class="col-md-4">
                <select
                  v-model="obat.bentuk_sediaan"
                  class="form-control m-b-5"
                >
                  <option value="">Pilih</option>
                  <option value="Kapsul">Kapsul</option>
                  <option value="Sirup">Sirup</option>
                  <option value="Tablet">Tablet</option>
                </select>
              </div>
              <div class="col-md-3">
                <input
                  v-model="obat.jumlah_obat"
                  class="form-control m-b-5"
                  placeholder="Jumlah Obat"
                />
              </div>
              <div class="col-md-5">
                <select
                  v-model="obat.metode_pemberian"
                  class="form-control m-b-5"
                  placeholder="Metode / Rute Pemberian"
                >
                  <option value="">Pilih</option>
                  <option value="IM">IM</option>
                  <option value="Subkutan">Subkutan</option>
                  <option value="IV">IV</option>
                  <option value="Oral">Oral</option>
                  <option value="Suppositoria">Suppositoria</option>
                  <option value="Topikal">Topikal</option>
                </select>
              </div>
              <div class="col-md-4">
                <input
                  v-model="obat.dosis_obat"
                  class="form-control m-b-5"
                  placeholder="Dosis Obat"
                />
              </div>
              <div class="col-md-3">
                <input
                  v-model="obat.unit_obat"
                  class="form-control m-b-5"
                  placeholder="Unit"
                />
              </div>
              <div class="col-md-5">
                <input
                  v-model="obat.frekuensi_interval"
                  class="form-control m-b-5"
                  placeholder="Frekuensi / Interval"
                />
              </div>
              <div class="col-md-12">
                <input
                  v-model="obat.aturan_tambahan"
                  class="form-control m-b-5"
                  placeholder="Aturan Tambahan"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button
          class="btn btn-block btn-info"
          @click="saveObatResep()"
          variant="primary"
        >
          <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
          Tambah Obat
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PageOptions from "../../../config/PageOptions.vue";
import Swal from "sweetalert2";
import axios from "axios";
import sipData from "../../../plugins/sipData";
import Loader from "../../../components/loader/Loader.vue";

export default {
  mixins: [sipData],
  props: ["kode_register", "no_rekam_medik", "nama_form"],
  components: {
    Loader,
  },
  data() {
    PageOptions.pageContentFullWidth = true;
    PageOptions.pageContentFullHeight = true;
    return {
      peresepan: {
        id_peresepan: "",
        kode_peresepan: "",
        kode_register: "",
        kode_rekam_medik: "",
        id_resep: "",
        catatan_resep: "",
        dokter_penulis_resep: "",
        telp_dokter_penulis_resep: "",
        tanggal_resep: "",
        jam_resep: "",
        ttd_dokter: "",
        status_resep: "",
        pengkajian_resep: "",
      },
      obat: {
        id_obat_peresepan: "",
        kode_obat_peresepan: "",
        kode_peresepan: "",
        nama_obat: "",
        bentuk_sediaan: "",
        jumlah_obat: "",
        metode_pemberian: "",
        dosis_obat: "",
        unit_obat: "",
        frekuensi_interval: "",
        aturan_tambahan: "",
      },
      riwayat_obat: [],
      formValidate: [],
      loadingData: false,
      loading: false,
      modalVisible: false,
      conditionMet: false,
      loadingDelete: {},
    };
  },
  mounted() {
    this.fetchResep();
    this.fetchObat();
  },
  methods: {
    saveObatResep() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.obat) {
        formData.append(key, this.obat[key]);
      }
      formData.append("kode_rekam_medik", this.no_rekam_medik);
      formData.append("kode_register", this.kode_register);
      axios
        .post("/v1/rekammedik/peresepan_obat", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.fetchObat();
            this.modalVisible = false;
            this.conditionMet = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    saveData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.peresepan) {
        formData.append(key, this.peresepan[key]);
      }
      formData.append("kode_rekam_medik", this.no_rekam_medik);
      formData.append("kode_register", this.kode_register);
      axios
        .post("/v1/rekammedik/peresepan", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            Swal.fire({
              title: "Sukses",
              text: "Data Telah Disimpan",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchResep() {
      this.loadingData = true;
      axios
        .get("/v1/rekammedik/peresepan/", {
          params: {
            kode_register: this.kode_register,
          },
        })
        .then((response) => {
          for (let key in response.data.data) {
            this.peresepan[key] = response.data.data[key];
          }
          this.loadingData = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchObat() {
      axios
        .get("/v1/rekammedik/peresepan_obat/", {
          params: {
            kode_register: this.kode_register,
          },
        })
        .then((response) => {
          this.riwayat_obat = response.data.data;
          this.loadingData = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    toggleForm(param) {
      this.$emit("formCode", param);
    },
    tambahBaris() {
      this.riwayat_obat.push({
        nama_obat: "",
        dosis: "",
        waktu_penggunaan: "",
      });
    },
    hapusBaris(index) {
      this.riwayat_obat.splice(index, 1);
    },

    openModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    deleteResepObat(param) {
      // Set status loading hanya untuk item yang sedang dihapus
      this.$set(this.loadingDelete, param, true);

      axios
        .get(`/v1/delete/resep_obat/${param}`)
        .then((response) => {
          console.log(response.status);
          this.fetchObat();
          // Reset status loading setelah penghapusan berhasil
          this.$set(this.loadingDelete, param, false);
        })
        .catch((error) => {
          // Reset status loading jika terjadi error
          this.$set(this.loadingDelete, param, false);
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
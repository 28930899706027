var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vertical-box"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"btn-toolbar"},[_c('div',{staticClass:"btn-group btn-block m-r-5"},[_c('a',{staticClass:"btn btn-default btn-sm p-l-auto p-r-auto",staticStyle:{"font-weight":"bold"},attrs:{"href":"javascript:;"}},[_vm._v(" "+_vm._s(_vm.nama_form)+" ")])])])]),_c('div',{staticClass:"vertical-box-row bg-white"},[_c('div',{staticClass:"vertical-box-cell"},[_c('div',{staticClass:"vertical-box-inner-cell"},[_c('vue-custom-scrollbar',{staticClass:"height-full"},[_c('div',{staticClass:"container mt-3"},[_c('div',[_c('div',{staticClass:"row"},[(this.loadingData)?_c('Loader'):_c('div',{staticClass:"container"},[_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"status_psikologis"}},[_vm._v("Status Psikologis")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_psikologis.status_psikologis),expression:"pemeriksaan_psikologis.status_psikologis"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.status_psikologis,
                        },attrs:{"list":"listPsikologi"},domProps:{"value":(_vm.pemeriksaan_psikologis.status_psikologis)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_psikologis, "status_psikologis", $event.target.value)}}}),_c('datalist',{attrs:{"id":"listPsikologi"}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"Tidak ada kelainan"}},[_vm._v(" Tidak ada kelainan ")]),_c('option',{attrs:{"value":"Cemas"}},[_vm._v("Cemas")]),_c('option',{attrs:{"value":"Takut"}},[_vm._v("Takut")]),_c('option',{attrs:{"value":"Marah"}},[_vm._v("Marah")]),_c('option',{attrs:{"value":"Sedih"}},[_vm._v("Sedih")])])])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"sosial_ekonomi"}},[_vm._v("Sosial Ekonomi ")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_psikologis.sosial_ekonomi),expression:"pemeriksaan_psikologis.sosial_ekonomi"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.sosial_ekonomi,
                        },domProps:{"value":(_vm.pemeriksaan_psikologis.sosial_ekonomi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_psikologis, "sosial_ekonomi", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"spiritual"}},[_vm._v("Spiritual")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_psikologis.spiritual),expression:"pemeriksaan_psikologis.spiritual"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.spiritual,
                        },domProps:{"value":(_vm.pemeriksaan_psikologis.spiritual)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_psikologis, "spiritual", $event.target.value)}}})])])])],1)])])])],1)])]),_c('div',{staticClass:"wrapper clearfix"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleForm(2)}}},[_vm._v(" Sebelumnya ")]),(!this.loadingData)?_c('button',{staticClass:"btn btn-teal pull-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveData(5)}}},[(this.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" Selanjutnya ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <ul class="nav">
    <li class="nav-profile">
      <a href="javascript:;">
        <div class="cover with-shadow"></div>
        <div class="image">
          <img :src="userPhoto" alt="" />
        </div>
        <div class="info">
          {{ userName }}
          <small>{{ userEmail }}</small>
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
import sipData from "../../plugins/sipData";

export default {
  name: "SidebarNavProfile",
  mixins: [sipData],
  data() {
    return {};
  },
};
</script>

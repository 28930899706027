<template>
  <div class="vertical-box">
    <div class="wrapper">
      <div class="btn-toolbar">
        <div class="btn-group btn-block m-r-5">
          <a
            href="javascript:;"
            class="btn btn-default btn-sm p-l-auto p-r-auto"
            style="font-weight: bold"
          >
            {{ nama_form }}
          </a>
        </div>
      </div>
    </div>

    <div class="vertical-box-row bg-white">
      <div class="vertical-box-cell">
        <div class="vertical-box-inner-cell">
          <vue-custom-scrollbar class="height-full">
            <div class="container mt-3">
              <div>
                <div class="row">
                  <Loader v-if="this.loadingData"></Loader>
                  <div v-else class="container">
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="keluhan_utama"
                        >Keluhan Utama</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="anamnesis.keluhan_utama"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.keluhan_utama,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="riwayat_penyakit"
                        >Riwayat Penyakit</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="anamnesis.riwayat_penyakit"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.riwayat_penyakit,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="riwayat_alergi"
                        >Riwayat Alergi</label
                      >
                      <div class="col-md-9">
                        <input
                          list="listAlergi"
                          v-model="anamnesis.riwayat_alergi"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.riwayat_alergi,
                          }"
                        />
                        <datalist id="listAlergi">
                          <option value="">Pilih</option>
                          <option value="Obat">Obat</option>
                          <option value="Makanan">Makanan</option>
                          <option value="Udara">Udara</option>
                        </datalist>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="riwayat_pengobatan"
                        >Riwayat Pengobatan</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="anamnesis.riwayat_pengobatan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.riwayat_pengobatan,
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-custom-scrollbar>
        </div>
      </div>
    </div>

    <div class="wrapper clearfix">
      <a href="javascript:window.history.go(-1);" class="btn btn-default"
        >Kembali</a
      >
      <button
        v-if="!this.loadingData"
        type="button"
        class="btn btn-teal pull-right"
        @click="saveData(2)"
      >
        <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
        Selanjutnya
      </button>
    </div>
  </div>
</template>

<script>
import PageOptions from "../../../config/PageOptions.vue";
import axios from "axios";
import sipData from "../../../plugins/sipData";
import Loader from "../../../components/loader/Loader.vue";

export default {
  mixins: [sipData],
  props: ["kode_register", "no_rekam_medik", "nama_form"],
  components: {
    Loader,
  },
  data() {
    PageOptions.pageContentFullWidth = true;
    PageOptions.pageContentFullHeight = true;
    return {
      anamnesis: {
        id_anamnesis: "",
        kode_anamnesis: "",
        kode_rekam_medik: "",
        kode_register: "",
        keluhan_utama: "",
        riwayat_penyakit: "",
        riwayat_alergi: "",
        riwayat_pengobatan: "",
      },
      formValidate: [],
      loadingData: false,
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    saveData(param) {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.anamnesis) {
        formData.append(key, this.anamnesis[key]);
      }
      formData.append("kode_rekam_medik", this.no_rekam_medik);
      formData.append("kode_register", this.kode_register);

      axios
        .post("/v1/rekammedik/anamnesis", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.$emit("formCode", param);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      this.loadingData = true;
      axios
        .get("/v1/rekammedik/anamnesis/", {
          params: {
            kode_register: this.kode_register,
          },
        })
        .then((response) => {
          for (let key in response.data.data) {
            this.anamnesis[key] = response.data.data[key];
          }
          this.loadingData = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
<template>
  <div class="login login-with-news-feed">
    <div class="news-feed bg-orange-transparent-1">
      <div
        class="news-image"
        style="
          background-image: url(https://cdn.smartpuskesmas.web.id/bg-login.webp);
        "
      ></div>
    </div>
    <div
      class="right-content bg-orange-transparent-1"
      style="
        background-image: url(https://cdn.smartpuskesmas.web.id/login-accent.webp);
        background-size: cover;
      "
    >
      <div class="login-header">
        <div class="brand">
          <img
            src="https://cdn.smartpuskesmas.web.id/rme-logo-text.png"
            alt="Logo"
            style="width: 90%"
          />
        </div>
      </div>
      <div class="login-content">
        <form @submit.prevent="login" method="POST" class="margin-bottom-0">
          <div class="form-group m-b-15">
            <input
              type="text"
              class="form-control form-control-lg"
              placeholder="Email/Username"
              id="username"
              v-model="username"
              required
            />
          </div>
          <div class="form-group m-b-15">
            <input
              type="password"
              class="form-control form-control-lg"
              placeholder="Password"
              id="password"
              v-model="password"
              required
            />
          </div>
          <div class="checkbox checkbox-css m-b-30">
            <input type="checkbox" id="remember_me_checkbox" value="" />
            <label for="remember_me_checkbox"> Remember Me </label>
          </div>
          <b-alert v-if="alert == 'error'" variant="danger" show fade>
            <strong>Login Gagal!</strong>
            <br />
            <span>{{ message }}</span>
          </b-alert>
          <b-alert v-if="alert == 'success'" variant="success" show fade>
            <strong>Login Success!</strong>
            <br />
            <span>{{ message }}</span>
          </b-alert>
          <div class="login-buttons">
            <button type="submit" class="btn btn-teal btn-block btn-lg">
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Login
            </button>
          </div>
          <div class="m-t-20 m-b-40 p-b-40 text-inverse">
            <!-- Belum terdaftar ? Klik
            <router-link to="/registrasi" class="text-success"
              >Registrasi</router-link
            > -->
          </div>
          <hr />
          <p class="text-center text-grey-darker">
            &copy; Dinkes Kota Palu 2022
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      alert: "",
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    login() {
      this.loading = true;
      this.alert = "";
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);

      axios
        .post("/v1/auth/login/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status == true) {
            this.loading = false;
            this.alert = "success";
            this.message = "Selamat Datang " + response.data.nama;

            setTimeout(() => {
              const sipData = {
                nama: response.data.nama,
                email: response.data.email,
                foto: response.data.foto,
                level: response.data.level,
                hak_akses: response.data.hak_akses,
                token: response.data.token,
              };
              localStorage.setItem("sipData", JSON.stringify(sipData));
              if (response.data.level === "admin") {
                this.$router.push("/dashboard");
              } else if (response.data.level === "user") {
                this.$router.push("/dashboard");
              } else {
                this.$router.push("/dashboard");
              }
            }, 2000);
          } else {
            this.loading = false;
            this.alert = "error";
            this.message = response.data.message;
            setTimeout(() => {
              this.alert = "";
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
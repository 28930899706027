<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel panel-primary">
          <div class="panel-heading bg-teal text-light">
            <h3 class="panel-title bold">Form</h3>
          </div>
          <form
            @submit.prevent="postData"
            id="formPeserta"
            enctype="multipart/form-data"
          >
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <input
                      v-model="form.id_users"
                      type="text"
                      hidden
                      name="id_users"
                      class="form-control m-b-5"
                    />
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="nomor_identitas">NIK</label>
                        <input
                          v-model="form.nomor_identitas"
                          type="text"
                          name="nomor_identitas"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.nomor_identitas,
                          }"
                        />
                        <div
                          class="text-danger"
                          v-html="formValidate.nomor_identitas"
                        ></div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group">
                        <label for="nama_lengkap">Nama Lengkap</label>
                        <input
                          v-model="form.nama_lengkap"
                          type="text"
                          name="nama_lengkap"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.nama_lengkap,
                          }"
                        />
                        <div
                          class="text-danger"
                          v-html="formValidate.nama_lengkap"
                        ></div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="email">Email</label>
                        <input
                          v-model="form.email"
                          type="email"
                          name="email"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.email,
                          }"
                        />
                        <div
                          class="text-danger"
                          v-html="formValidate.email"
                        ></div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="no_handphone">Nomor HP/WA</label>
                        <input
                          v-model="form.no_handphone"
                          type="text"
                          name="no_handphone"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.no_handphone,
                          }"
                        />
                        <div
                          class="text-danger"
                          v-html="formValidate.no_handphone"
                        ></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="password"
                          >Password <span class="text-danger">*</span></label
                        >
                        <input
                          type="password"
                          v-model="form.password"
                          class="form-control"
                          placeholder="Password"
                          :class="{
                            'is-invalid': formValidate.password,
                          }"
                        />
                        <small
                          class="text-danger"
                          v-html="formValidate.password"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="confirm_password"
                          >Ulangi Password
                          <span class="text-danger">*</span></label
                        >
                        <input
                          type="password"
                          v-model="form.confirm_password"
                          class="form-control"
                          placeholder="Ulangi Password"
                          :class="{
                            'is-invalid': formValidate.confirm_password,
                          }"
                        />
                        <small
                          class="text-danger"
                          v-html="formValidate.confirm_password"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="level">Level User</label>
                        <select
                          v-model="form.level"
                          name="level"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.level,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="admin">Admin</option>
                          <option value="admin_dinkes">Admin Dinkes</option>
                          <option value="admin_sip">Admin SIP</option>
                          <option value="admin_sian">Admin SIAN</option>
                          <option value="admin_ruang">Admin Ruang</option>
                          <option value="admin_lab">Admin Lab</option>
                          <option value="admin_apotik">Admin Apotik</option>
                          <option value="user_kiosk">User Kiosk</option>
                          <option value="user_display">User Display</option>
                        </select>
                        <div
                          class="text-danger"
                          v-html="formValidate.level"
                        ></div>
                      </div>
                    </div>

                    <div
                      class="col-md-2"
                      v-if="
                        form.level == 'admin_dinkes' ||
                        form.level == 'admin_sian' ||
                        form.level == 'admin_ruang' ||
                        form.level == 'admin_lab' ||
                        form.level == 'admin_apotik' ||
                        form.level == 'user_kiosk' ||
                        form.level == 'user_display'
                      "
                    >
                      <div class="form-group">
                        <label for="faskes">Puskesmas</label>
                        <select
                          v-model="form.faskes"
                          name="faskes"
                          class="form-control m-b-5"
                          v-on:change="getHakAkses($event)"
                          :class="{
                            'is-invalid': formValidate.faskes,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option
                            v-for="faskes in opsiFaskes"
                            :key="faskes.kode_faskes"
                            :value="faskes.kode_faskes"
                          >
                            {{ faskes.nama_faskes }}
                          </option>
                        </select>
                        <div
                          class="text-danger"
                          v-html="formValidate.level"
                        ></div>
                      </div>
                    </div>

                    <div class="col-md-2" v-if="form.level == 'admin_ruang'">
                      <div class="form-group">
                        <label for="hak_akses">Pemeriksaan</label>
                        <select
                          v-model="form.hak_akses"
                          name="hak_akses"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.hak_akses,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option
                            v-for="hak_akses in opsiHakAkses"
                            :key="hak_akses.id_poliklinik"
                            :value="hak_akses.id_poliklinik"
                          >
                            {{ hak_akses.nama_poli }}
                          </option>
                        </select>
                        <div
                          class="text-danger"
                          v-html="formValidate.hak_akses"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-footer">
              <a
                href="javascript:window.history.go(-1);"
                class="btn btn-default"
                >Batal</a
              >
              <button
                type="button"
                class="btn btn-teal pull-right"
                @click="postData()"
                :disabled="this.loading"
              >
                <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
                {{ $route.params.id ? "Update" : "Submit" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import "cropperjs/dist/cropper.css";

export default {
  data() {
    return {
      form: {
        id_users: "",
        faskes: "",
        nomor_identitas: "",
        nama_lengkap: "",
        no_handphone: "",
        email: "",
        level: "",
        hak_akses: "",
        password: "",
        confirm_password: "",
      },
      opsiFaskes: [],
      opsiHakAkses: [],
      formValidate: [],
      loading: false,
    };
  },
  mounted() {
    this.getFaskes();
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post("/v1/auth/registrasi/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.go(-1);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getFaskes() {
      let kode = "01";
      axios
        .get("/v1/faskes/" + kode)
        .then((response) => {
          this.opsiFaskes = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getHakAkses(event) {
      this.form.faskes = event.target.value;
      axios
        .get("/v1/poliklinik/opsi/" + this.form.faskes)
        .then((response) => {
          this.opsiHakAkses = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>

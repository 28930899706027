import Vue from "vue";
import VueRouter from "vue-router";

import axios from "axios";
import Swal from "sweetalert2";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";
import Login from "../pages/Login.vue";
import Registrasi from "../pages/Registrasi.vue";

import Pasien from "../pages/pasien/Index.vue";
import PasienForm from "../pages/pasien/Form.vue";
import PasienDetail from "../pages/pasien/Detail.vue";

import Profil from "../pages/profil/Profil.vue";

import Register from "../pages/register/Index.vue";
import RegisterNew from "../pages/register/New.vue";
import RegisterForm from "../pages/register/Form.vue";
import RegisterDetail from "../pages/register/Detail.vue";
import RegisterResep from "../pages/register/Resep.vue";

import PelayananList from "../pages/ruang-pelayanan/List.vue";
import PelayananForm from "../pages/ruang-pelayanan/Form.vue";

import Kunjungan from "../pages/kunjungan/Index.vue";

const routes = [{
    path: "/",
    redirect: "/dashboard/",
  },
  {
    path: "*",
    redirect: "/dashboard/"
  },
  {
    path: "/dashboard",
    component: Dashboard
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/registrasi",
    component: Registrasi
  },
  {
    path: "/pasien",
    component: Pasien
  },
  {
    path: "/pasien/create",
    component: PasienForm
  },
  {
    path: "/pasien/update/:id",
    component: PasienForm
  },
  {
    path: "/pasien/detail/:id",
    component: PasienDetail
  },
  {
    path: "/register",
    component: Register
  },
  {
    path: "/register/create",
    component: RegisterNew
  },
  {
    path: "/register/formulir/:id",
    component: RegisterForm
  },
  {
    path: "/register/detail/:id",
    component: RegisterDetail
  },
  {
    path: "/register/resep/:id",
    component: RegisterResep
  },
  {
    path: "/kunjungan",
    component: Kunjungan
  },
  {
    path: "/ruang-pelayanan",
    component: PelayananList
  },
  {
    path: "/ruang-pelayanan/create",
    component: PelayananForm
  },
  {
    path: "/profil",
    component: Profil
  },
  {
    path: "/profil/:page",
    component: Profil
  },
];

const router = new VueRouter({
  mode: "history",
  // base: "/eregister/",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("sipData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !isLoggedIn && to.path !== "/registrasi") {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }

  const sipData = localStorage.getItem("sipData");
  if (sipData) {
    const user = JSON.parse(sipData);
    const token = user.token;

    // const formData = new FormData();
    // formData.append("token", token);
    axios
      // .post("/api/auth/status/", formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // })
      .get("/v1/auth/status/", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Session Habis, Silahkan Login Kembali",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            localStorage.removeItem("sipData");
            if (to.path !== "/login") {
              next("/login");
            }
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default router;
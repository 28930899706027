<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-teal text-light">
          <h3 class="panel-title bold">Form Register</h3>
        </div>
        <form @submit.prevent="postData" enctype="multipart/form-data">
          <div class="panel-body">
            <div class="container mt-3">
              <div class="col-md-12">
                <input
                  type="text"
                  v-model="form.id_register"
                  hidden
                  name="id_register"
                />
              </div>

              <div class="form-group row m-b-15">
                <label class="col-form-label col-md-4">Tanggal, Jam</label>
                <div class="col-md-6">
                  <div class="input-group">
                    <input
                      v-model="form.tanggal"
                      name="tanggal"
                      type="date"
                      class="form-control m-b-5"
                      placeholder="Tanggal Lahir"
                      :class="{
                        'is-invalid': formValidate.tanggal,
                      }"
                    />
                    <input
                      v-model="form.jam"
                      name="jam"
                      type="time"
                      class="form-control m-b-5"
                      placeholder="Jam Lahir"
                      :class="{
                        'is-invalid': formValidate.jam,
                      }"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row m-b-15">
                <label class="col-md-4 col-form-label" for="nik">NIK</label>
                <div class="col-md-6">
                  <input
                    v-model="search.pasien"
                    type="text"
                    class="form-control"
                    placeholder="Masukkan NIK/Nama Pasien"
                    @input="handlePasien"
                    :class="{
                      'is-invalid': formValidate.nik,
                    }"
                  />
                </div>
                <div class="col-md-2">
                  <router-link
                    to="/pasien/create"
                    class="btn btn-primary btn-block"
                  >
                    Pasien Baru
                  </router-link>
                </div>
              </div>

              <div class="form-group row m-b-15" v-if="showPasien">
                <label class="col-form-label col-md-4"></label>
                <div class="col-md-8">
                  <table style="width: 100%">
                    <tr
                      v-for="pasien in rows.pasien"
                      v-bind:key="pasien.nik"
                      @click="setPasien(pasien.nik, pasien.nama)"
                    >
                      <td>
                        <a
                          class="btn btn-sm btn-block btn-default"
                          style="text-align: left"
                          href="javascript:;"
                          >{{ pasien.nik }} : {{ pasien.nama }}</a
                        >
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="form-group row m-b-15">
                <label
                  class="col-md-4 col-form-label push-right"
                  for="riwayat_alergi"
                  >Ruang</label
                >
                <div class="col-md-8">
                  <input
                    list="listAlergi"
                    v-model="form.riwayat_alergi"
                    class="form-control m-b-5"
                    :class="{
                      'is-invalid': formValidate.riwayat_alergi,
                    }"
                  />
                  <datalist id="listAlergi">
                    <option value="">Pilih</option>
                    <option value="IGD">IGD</option>
                    <option value="Poli Umum">Poli Umum</option>
                    <option value="Poli Lansia">Poli Lansia</option>
                  </datalist>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Batal</a
            >
            <button
              type="button"
              class="btn btn-teal pull-right"
              @click="postData()"
              :disabled="this.loading"
            >
              <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
              {{ $route.params.id ? "Update" : "Submit" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      form: {
        id_register: "",
        kode_register: "",
        pasien: "",
        tanggal: "",
        jam: "",
      },
      search: {
        pasien: "",
      },
      rows: {
        pasien: [],
      },
      link: "/api/register/create",
      formValidate: [],
      loading: false,
      showUserAccess: true,
      timeoutId: null,
      showPasien: false,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.link = "/api/register/update";
      this.form.id_register = id;
      this.fetchData();
    } else {
      this.form.id = "";
    }
  },
  methods: {
    postData() {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/api/register/detail/" + this.form.id_register)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
          this.search.pasien =
            response.data.data["pasien"] +
            " : " +
            response.data.data["nama_pasien"];
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    handlePasien() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.searchPasien();
      }, 500);
    },
    searchPasien() {
      const formData = new FormData();
      formData.append("search", this.search.pasien);
      axios
        .post("/api/identitas_umum_pasien/search/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows.pasien = response.data.data || [];
          if (this.rows.pasien.length <= 0) {
            this.showPasien = false;
          } else {
            this.showPasien = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setPasien(nik, nama) {
      this.form.pasien = nik;
      this.search.pasien = nik + " : " + nama;
      this.showPasien = false;
      this.rows.pasien = [];
    },
  },
};
</script>

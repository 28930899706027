<template>
  <div>
    <div class="profile">
      <div class="profile-header">
        <div class="profile-header-cover"></div>
        <div class="profile-header-content">
          <div class="profile-header-img">
            <img
              :src="cropImg"
              alt="foto"
              @click.prevent="showFileChooser"
              v-b-modal.modalDialog
            />
          </div>
          <div class="profile-header-info m-b-15">
            <h4 class="m-t-10 m-b-5">{{ userName }}</h4>
            <p class="m-b-15">{{ userEmail }}</p>
            <a
              href="javascript:;"
              v-on:click="show('editProfil')"
              class="btn btn-xs btn-yellow"
              >Edit Profil</a
            >
            <a
              href="javascript:;"
              v-on:click="show('editPassword')"
              class="btn btn-xs btn-danger ml-2"
              >Ganti Password</a
            >
          </div>
        </div>
        <ul class="profile-header-tab nav nav-tabs">
          <li class="nav-item">
            <a
              href="javascript:;"
              v-on:click="show('about')"
              v-bind:class="{ active: tab.about }"
              class="nav-link"
              data-toggle="tab"
              >PROFIL</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="profile-content">
      <div class="tab-content p-0">
        <div class="tab-pane fade" v-bind:class="{ 'show active': tab.about }">
          <div class="table-responsive form-inline">
            <table class="table table-profile">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <h4>
                      {{ userName }}<small>{{ userEmail }}</small>
                    </h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="highlight">
                  <td class="field valign-middle">NIK</td>
                  <td>{{ this.form.nomor_identitas }}</td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr class="highlight">
                  <td class="field valign-middle">No. HP/WA</td>
                  <td>
                    <i class="fa fa-mobile fa-lg m-r-5"></i>
                    {{ this.form.no_handphone }}
                  </td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          v-bind:class="{ 'show active': tab.editProfil }"
        >
          <form
            @submit.prevent="postData"
            id="formPeserta"
            enctype="multipart/form-data"
          >
            <div class="table-responsive form-inline">
              <input
                v-model="form.id_users"
                type="text"
                hidden
                name="id_users"
                class="form-control m-b-5"
              />
              <input
                v-model="form.foto_lama"
                type="text"
                hidden
                name="foto_lama"
                class="form-control m-b-5"
              />
              <table class="table table-profile">
                <tbody>
                  <tr>
                    <td class="field valign-middle">Nama Lengkap</td>
                    <td>
                      <input
                        v-model="form.nama_lengkap"
                        type="text"
                        name="nama_lengkap"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.nama_lengkap,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.nama_lengkap"
                      ></div>
                    </td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">NIK</td>
                    <td>
                      <masked-input
                        v-model="form.nomor_identitas"
                        type="text"
                        name="nomor_identitas"
                        class="form-control m-b-5"
                        mask="1111111111111111"
                        :class="{
                          'is-invalid': formValidate.nomor_identitas,
                        }"
                      ></masked-input>
                      <div
                        class="text-danger"
                        v-html="formValidate.nomor_identitas"
                      ></div>
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td class="field valign-middle">Email</td>
                    <td>
                      <input
                        v-model="form.email"
                        type="email"
                        name="email"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.email,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.email"
                      ></div>
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">No. HP/WA</td>
                    <td>
                      <masked-input
                        v-model="form.no_handphone"
                        type="text"
                        name="no_handphone"
                        class="form-control m-b-5"
                        mask="1111-1111-1111"
                        :class="{
                          'is-invalid': formValidate.no_handphone,
                        }"
                      ></masked-input>
                      <div
                        class="text-danger"
                        v-html="formValidate.no_handphone"
                      ></div>
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>

                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <div>
                        <a
                          href="#"
                          class="btn btn-primary m-1"
                          role="button"
                          @click.prevent="showFileChooser"
                          v-b-modal.modalDialog
                        >
                          <i class="fa fa-fw fa-image"></i> Pilih/Ambil Foto
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <div class="col-md-4">
                        <input
                          style="display: none"
                          ref="input"
                          type="file"
                          name="image"
                          accept="image/*"
                          @change="setImage"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <button
                        type="button"
                        class="btn btn-success mt-4"
                        @click="postData()"
                        :disabled="this.loading"
                      >
                        <i
                          v-if="this.loading"
                          class="fas fa-circle-notch fa-spin"
                        ></i>
                        Update Profil
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <div
          class="tab-pane fade"
          v-bind:class="{ 'show active': tab.editPassword }"
        >
          <form @submit.prevent="updatePassword" enctype="multipart/form-data">
            <div class="table-responsive form-inline">
              <input
                v-model="form.id_pendukung"
                type="text"
                hidden
                name="id_pendukung"
                class="form-control m-b-5"
              />
              <table class="table table-profile">
                <tbody>
                  <tr>
                    <td class="field">Password Lama</td>
                    <td>
                      <input
                        v-model="form.password_lama"
                        type="password"
                        name="password_lama"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.password_lama,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.password_lama"
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="field">Password Baru</td>
                    <td>
                      <input
                        v-model="form.password_baru"
                        type="password"
                        name="password_baru"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.password_baru,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.password_baru"
                      ></div>
                    </td>
                  </tr>
                  <tr class="highlight">
                    <td class="field">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <button
                        type="button"
                        class="btn btn-success mt-4"
                        @click="updatePassword()"
                        :disabled="this.loading"
                      >
                        <i
                          v-if="this.loading"
                          class="fas fa-circle-notch fa-spin"
                        ></i>
                        Ganti Password
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
    <b-modal id="modalDialog" cancel-variant="default" title="">
      <div>
        <div class="row">
          <div class="cropper-area">
            <div class="img-cropper">
              <vue-cropper
                ref="cropper"
                :aspect-ratio="1 / 1"
                :src="imgSrc"
                preview=".preview"
                style="width: 100%; border-radius: 5px; overflow: hidden"
              />
              <div v-if="this.imgSrc">
                <div class="btn-group mt-1">
                  <a
                    href="#"
                    class="btn btn-sm btn-primary"
                    role="button"
                    @click.prevent="rotate(90)"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-sm btn-primary"
                    role="button"
                    @click.prevent="rotate(-90)"
                  >
                    <i class="fa fa-redo fa-flip-horizontal"></i>
                  </a>
                  <a
                    href="#"
                    class="btn btn-sm btn-primary"
                    role="button"
                    @click.prevent="cropImage"
                  >
                    <i class="fa fa-crop"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import sipData from "../../plugins/sipData";
import axios from "axios";
import Swal from "sweetalert2";
import MaskedInput from "vue-masked-input";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  mixins: [sipData],
  components: {
    MaskedInput,
    VueCropper,
  },
  data() {
    return {
      token: "",
      tab: {
        about: true,
        editProfil: false,
        editPassword: false,
      },
      form: {
        id_users: "",
        nama_lengkap: "",
        no_handphone: "",
        email: "",
        nomor_identitas: "",
        level: "",
        foto: "",
        foto_lama: "",
        foto_crop: "",
        password_lama: "",
        password_baru: "",
      },
      link: "/api/pendukung/update",
      imgSrc: "",
      cropImg: "/assets/img/user.png",
      formValidate: [],
      loading: false,
    };
  },
  created() {
    PageOptions.pageContentFullWidth = true;
    this.fetchData();
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageContentFullWidth = false;
    next();
  },
  methods: {
    show: function (x) {
      this.tab.about = false;
      this.tab.editProfil = false;
      this.tab.editPassword = false;

      switch (x) {
        case "about":
          this.tab.about = true;
          break;
        case "editProfil":
          this.tab.editProfil = true;
          break;
        case "editPassword":
          this.tab.editPassword = true;
          break;
        default:
          this.tab.about = true;
          break;
      }
    },
    updatePassword() {
      this.loading = true;
      const formData = new FormData();

      formData.append("id_pendukung", this.form.id_pendukung);
      formData.append("password_lama", this.form.password_lama);
      formData.append("password_baru", this.form.password_baru);
      // console.log(formData.get("foto"));
      axios
        .post("/api/auth/changePassword/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              if (response.data.status) {
                localStorage.removeItem("user");
                if (this.$route.path !== "/login") {
                  this.$router.push("/login");
                }
              }
              // this.$router.push("/profil");
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();

      if (this.cropImg.includes("http") || this.cropImg == "") {
        // formData.append("foto", "");
      } else {
        const blob = this.dataURLtoBlob(this.cropImg);
        formData.append("foto", blob, "foto.png");
      }

      formData.append("id_pendukung", this.form.id_pendukung);
      formData.append("no_urut", this.form.no_urut);
      formData.append("no_pendukung", this.form.no_pendukung);
      formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("nama_panggilan", this.form.nama_panggilan);
      formData.append("no_handphone", this.form.no_handphone);
      formData.append("email_pendukung", this.form.email_pendukung);
      formData.append("nomor_identitas", this.form.nomor_identitas);
      formData.append("kabupaten", this.form.kabupaten);
      formData.append("kecamatan", this.form.kecamatan);
      formData.append("kelurahan", this.form.kelurahan);
      formData.append("rw", this.form.rw);
      formData.append("tps", this.form.tps);
      formData.append("suku", this.form.suku);
      formData.append("level", this.form.level);
      formData.append("tim_relawan_tps", this.form.tim_relawan_tps);
      formData.append("foto_lama", this.form.foto_lama);

      // console.log(formData.get("foto"));
      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              if (response.data.status) {
                localStorage.removeItem("user");
                if (this.$route.path !== "/login") {
                  this.$router.push("/login");
                }
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/api/profil/detail/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.form.id_users = response.data.data.id_users;
          this.form.nama_lengkap = response.data.data.nama_lengkap;
          this.form.no_handphone = response.data.data.no_handphone;
          this.form.email = response.data.data.email;
          this.form.nomor_identitas = response.data.data.nomor_identitas;
          this.form.level = response.data.data.level;
          this.form.foto_lama = response.data.data.foto;
          this.cropImg = response.data.data.link_foto;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    dataURLtoBlob(dataURL) {
      const base64Data = dataURL.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
  },
};
</script>
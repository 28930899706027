var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vertical-box"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"btn-toolbar"},[_c('div',{staticClass:"btn-group btn-block m-r-5"},[_c('a',{staticClass:"btn btn-default btn-sm p-l-auto p-r-auto",staticStyle:{"font-weight":"bold"},attrs:{"href":"javascript:;"}},[_vm._v(" "+_vm._s(_vm.nama_form)+" ")])])])]),_c('div',{staticClass:"vertical-box-row bg-white"},[_c('div',{staticClass:"vertical-box-cell"},[_c('div',{staticClass:"vertical-box-inner-cell"},[_c('vue-custom-scrollbar',{staticClass:"height-full"},[_c('div',{staticClass:"container mt-3"},[_c('div',[_c('div',{staticClass:"row"},[(this.loadingData)?_c('Loader'):_c('div',{staticClass:"container"},[(this.userAccess != 'igd')?_c('div',[_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"assesmen_nyeri"}},[_vm._v("Asesmen nyeri")]),_c('div',{staticClass:"col-md-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.assesmen_nyeri),expression:"pemeriksaan_fisik.assesmen_nyeri"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.assesmen_nyeri,
                          },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.pemeriksaan_fisik, "assesmen_nyeri", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Ada"}},[_vm._v("Ada")]),_c('option',{attrs:{"value":"Tidak Ada"}},[_vm._v("Tidak Ada")])])])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"skala_nyeri"}},[_vm._v("Skala nyeri")]),_c('div',{staticClass:"col-md-9"},[_c('ul',{staticClass:"pagination"},_vm._l(([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),function(skala){return _c('li',{key:skala,staticClass:"page-item text-center",class:{
                              active:
                                parseInt(_vm.pemeriksaan_fisik.skala_nyeri) ===
                                skala,
                            },staticStyle:{"min-width":"40px","cursor":"pointer"}},[_c('span',{staticClass:"page-link",on:{"click":function($event){_vm.pemeriksaan_fisik.skala_nyeri = skala}}},[_vm._v(_vm._s(skala))])])}),0)])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"lokasi_nyeri"}},[_vm._v("Lokasi nyeri")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.lokasi_nyeri),expression:"pemeriksaan_fisik.lokasi_nyeri"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.lokasi_nyeri,
                          },attrs:{"type":"text"},domProps:{"value":(_vm.pemeriksaan_fisik.lokasi_nyeri)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "lokasi_nyeri", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"penyebab_nyeri"}},[_vm._v("Penyebab nyeri")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.penyebab_nyeri),expression:"pemeriksaan_fisik.penyebab_nyeri"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.penyebab_nyeri,
                          },attrs:{"type":"text"},domProps:{"value":(_vm.pemeriksaan_fisik.penyebab_nyeri)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "penyebab_nyeri", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"durasi_nyeri"}},[_vm._v("Durasi nyeri")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.durasi_nyeri),expression:"pemeriksaan_fisik.durasi_nyeri"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.durasi_nyeri,
                          },attrs:{"type":"text"},domProps:{"value":(_vm.pemeriksaan_fisik.durasi_nyeri)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "durasi_nyeri", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"frekuensi_nyeri"}},[_vm._v("Frekuensi nyeri")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.frekuensi_nyeri),expression:"pemeriksaan_fisik.frekuensi_nyeri"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.frekuensi_nyeri,
                          },attrs:{"type":"text"},domProps:{"value":(_vm.pemeriksaan_fisik.frekuensi_nyeri)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "frekuensi_nyeri", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"risiko_jatuh"}},[_vm._v("Kajian Risiko jatuh")]),_c('div',{staticClass:"col-md-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.risiko_jatuh),expression:"pemeriksaan_fisik.risiko_jatuh"}],staticClass:"form-control m-b-5",class:{
                            'is-invalid': _vm.formValidate.risiko_jatuh,
                          },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.pemeriksaan_fisik, "risiko_jatuh", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0 - 24 (risiko rendah)"}},[_vm._v(" 0 - 24 (risiko rendah) ")]),_c('option',{attrs:{"value":"25 - 44 (risiko sedang)"}},[_vm._v(" 25 - 44 (risiko sedang) ")]),_c('option',{attrs:{"value":"> 45 (risiko tinggi)"}},[_vm._v(" > 45 (risiko tinggi) ")])])])])]):_vm._e(),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"keadaaan_umum"}},[_vm._v("Keadaan Umum")]),_c('div',{staticClass:"col-md-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.keadaaan_umum),expression:"pemeriksaan_fisik.keadaaan_umum"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.keadaaan_umum,
                        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.pemeriksaan_fisik, "keadaaan_umum", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"Baik"}},[_vm._v("Baik")]),_c('option',{attrs:{"value":"Sedang"}},[_vm._v("Sedang")]),_c('option',{attrs:{"value":"Lemah"}},[_vm._v("Lemah")])])])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"tingkat_kesadaran"}},[_vm._v("Tingkat kesadaran")]),_c('div',{staticClass:"col-md-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.tingkat_kesadaran),expression:"pemeriksaan_fisik.tingkat_kesadaran"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.tingkat_kesadaran,
                        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.pemeriksaan_fisik, "tingkat_kesadaran", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"Sadar Baik/Alert : 0"}},[_vm._v(" Sadar Baik/Alert : 0 ")]),_c('option',{attrs:{"value":"Berespon dengan  kata-kata/Voice : 1"}},[_vm._v(" Berespon dengan kata-kata/Voice : 1 ")]),_c('option',{attrs:{"value":"Hanya berespons  jika dirangsang  nyeri/pain : 2"}},[_vm._v(" Hanya berespons jika dirangsang nyeri/pain : 2 ")]),_c('option',{attrs:{"value":"Pasien tidak  sadar/unresponsive  : 3"}},[_vm._v(" Pasien tidak sadar/unresponsive : 3 ")]),_c('option',{attrs:{"value":"Gelisah atau  bingung : 4"}},[_vm._v(" Gelisah atau bingung : 4 ")]),_c('option',{attrs:{"value":"Acute Confusional  States : 5"}},[_vm._v(" Acute Confusional States : 5 ")])])])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"tingkat_kesadaran"}},[_vm._v("Tinggi Badan (cm)")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.tinggi_badan),expression:"pemeriksaan_fisik.tinggi_badan"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.tinggi_badan,
                        },domProps:{"value":(_vm.pemeriksaan_fisik.tinggi_badan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "tinggi_badan", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row m-b-15"},[_c('label',{staticClass:"col-md-3 col-form-label push-right",attrs:{"for":"tingkat_kesadaran"}},[_vm._v("Berat Badan (kg)")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.berat_badan),expression:"pemeriksaan_fisik.berat_badan"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.berat_badan,
                        },domProps:{"value":(_vm.pemeriksaan_fisik.berat_badan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "berat_badan", $event.target.value)}}})])]),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading bg-grey-transparent-2"},[_vm._v(" Tanda-tanda Vital ")]),_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.denyut_jantung),expression:"pemeriksaan_fisik.denyut_jantung"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.denyut_jantung,
                                },attrs:{"type":"text","placeholder":"Denyut jantung"},domProps:{"value":(_vm.pemeriksaan_fisik.denyut_jantung)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "denyut_jantung", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.pernapasan),expression:"pemeriksaan_fisik.pernapasan"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.pernapasan,
                                },attrs:{"type":"text","placeholder":"Pernapasan"},domProps:{"value":(_vm.pemeriksaan_fisik.pernapasan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "pernapasan", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.sistole),expression:"pemeriksaan_fisik.sistole"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.sistole,
                                },attrs:{"type":"int","placeholder":"*Sistole"},domProps:{"value":(_vm.pemeriksaan_fisik.sistole)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "sistole", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.diastole),expression:"pemeriksaan_fisik.diastole"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.diastole,
                                },attrs:{"type":"int","placeholder":"*Diastole"},domProps:{"value":(_vm.pemeriksaan_fisik.diastole)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "diastole", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.suhu_tubuh),expression:"pemeriksaan_fisik.suhu_tubuh"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.suhu_tubuh,
                                },attrs:{"type":"text","placeholder":"Suhu tubuh"},domProps:{"value":(_vm.pemeriksaan_fisik.suhu_tubuh)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "suhu_tubuh", $event.target.value)}}})])])])])])]),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading bg-grey-transparent-2"},[_vm._v(" Tanda-tanda Fisik ")]),_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.kepala),expression:"pemeriksaan_fisik.kepala"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.kepala,
                                },attrs:{"type":"text","placeholder":"Kepala"},domProps:{"value":(_vm.pemeriksaan_fisik.kepala)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "kepala", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.mata),expression:"pemeriksaan_fisik.mata"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.mata,
                                },attrs:{"type":"text","placeholder":"Mata"},domProps:{"value":(_vm.pemeriksaan_fisik.mata)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "mata", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.telinga),expression:"pemeriksaan_fisik.telinga"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.telinga,
                                },attrs:{"type":"text","placeholder":"Telinga"},domProps:{"value":(_vm.pemeriksaan_fisik.telinga)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "telinga", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.hidung),expression:"pemeriksaan_fisik.hidung"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.hidung,
                                },attrs:{"type":"text","placeholder":"Hidung"},domProps:{"value":(_vm.pemeriksaan_fisik.hidung)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "hidung", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.rambut),expression:"pemeriksaan_fisik.rambut"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.rambut,
                                },attrs:{"type":"text","placeholder":"Rambut"},domProps:{"value":(_vm.pemeriksaan_fisik.rambut)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "rambut", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.bibir),expression:"pemeriksaan_fisik.bibir"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.bibir,
                                },attrs:{"type":"text","placeholder":"Bibir"},domProps:{"value":(_vm.pemeriksaan_fisik.bibir)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "bibir", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.gigi_geligi),expression:"pemeriksaan_fisik.gigi_geligi"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.gigi_geligi,
                                },attrs:{"type":"text","placeholder":"Gigi Geligi"},domProps:{"value":(_vm.pemeriksaan_fisik.gigi_geligi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "gigi_geligi", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.lidah),expression:"pemeriksaan_fisik.lidah"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.lidah,
                                },attrs:{"type":"text","placeholder":"Lidah"},domProps:{"value":(_vm.pemeriksaan_fisik.lidah)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "lidah", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.langit_langit),expression:"pemeriksaan_fisik.langit_langit"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.langit_langit,
                                },attrs:{"type":"text","placeholder":"Langit-langit"},domProps:{"value":(_vm.pemeriksaan_fisik.langit_langit)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "langit_langit", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.leher),expression:"pemeriksaan_fisik.leher"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.leher,
                                },attrs:{"type":"text","placeholder":"Leher"},domProps:{"value":(_vm.pemeriksaan_fisik.leher)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "leher", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.tenggorokan),expression:"pemeriksaan_fisik.tenggorokan"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.tenggorokan,
                                },attrs:{"type":"text","placeholder":"Tenggorokan"},domProps:{"value":(_vm.pemeriksaan_fisik.tenggorokan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "tenggorokan", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.tonsil),expression:"pemeriksaan_fisik.tonsil"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.tonsil,
                                },attrs:{"type":"text","placeholder":"Tonsil"},domProps:{"value":(_vm.pemeriksaan_fisik.tonsil)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "tonsil", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.dada),expression:"pemeriksaan_fisik.dada"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.dada,
                                },attrs:{"type":"text","placeholder":"Dada"},domProps:{"value":(_vm.pemeriksaan_fisik.dada)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "dada", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.payudara),expression:"pemeriksaan_fisik.payudara"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.payudara,
                                },attrs:{"type":"text","placeholder":"Payudara"},domProps:{"value":(_vm.pemeriksaan_fisik.payudara)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "payudara", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.punggung),expression:"pemeriksaan_fisik.punggung"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.punggung,
                                },attrs:{"type":"text","placeholder":"Punggung"},domProps:{"value":(_vm.pemeriksaan_fisik.punggung)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "punggung", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.perut),expression:"pemeriksaan_fisik.perut"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.perut,
                                },attrs:{"type":"text","placeholder":"Perut"},domProps:{"value":(_vm.pemeriksaan_fisik.perut)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "perut", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.genital),expression:"pemeriksaan_fisik.genital"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.genital,
                                },attrs:{"type":"text","placeholder":"Genital"},domProps:{"value":(_vm.pemeriksaan_fisik.genital)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "genital", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.anus_dubur),expression:"pemeriksaan_fisik.anus_dubur"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.anus_dubur,
                                },attrs:{"type":"text","placeholder":"Anus/Dubur"},domProps:{"value":(_vm.pemeriksaan_fisik.anus_dubur)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "anus_dubur", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.lengan_atas),expression:"pemeriksaan_fisik.lengan_atas"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.lengan_atas,
                                },attrs:{"type":"text","placeholder":"Lengan Atas"},domProps:{"value":(_vm.pemeriksaan_fisik.lengan_atas)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "lengan_atas", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.lengan_bawah),expression:"pemeriksaan_fisik.lengan_bawah"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.lengan_bawah,
                                },attrs:{"type":"text","placeholder":"Lengan Bawah"},domProps:{"value":(_vm.pemeriksaan_fisik.lengan_bawah)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "lengan_bawah", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.jari_tangan),expression:"pemeriksaan_fisik.jari_tangan"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.jari_tangan,
                                },attrs:{"type":"text","placeholder":"Jari Tangan"},domProps:{"value":(_vm.pemeriksaan_fisik.jari_tangan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "jari_tangan", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.kuku_tangan),expression:"pemeriksaan_fisik.kuku_tangan"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.kuku_tangan,
                                },attrs:{"type":"text","placeholder":"Kuku Tangan"},domProps:{"value":(_vm.pemeriksaan_fisik.kuku_tangan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "kuku_tangan", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.persendian_tangan),expression:"pemeriksaan_fisik.persendian_tangan"}],staticClass:"form-control",class:{
                                  'is-invalid':
                                    _vm.formValidate.persendian_tangan,
                                },attrs:{"type":"text","placeholder":"Persendian Tangan"},domProps:{"value":(_vm.pemeriksaan_fisik.persendian_tangan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "persendian_tangan", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.tungkai_atas),expression:"pemeriksaan_fisik.tungkai_atas"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.tungkai_atas,
                                },attrs:{"type":"text","placeholder":"Tungkai Atas"},domProps:{"value":(_vm.pemeriksaan_fisik.tungkai_atas)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "tungkai_atas", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.tungkai_bawah),expression:"pemeriksaan_fisik.tungkai_bawah"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.tungkai_bawah,
                                },attrs:{"type":"text","placeholder":"Tungkai Bawah"},domProps:{"value":(_vm.pemeriksaan_fisik.tungkai_bawah)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "tungkai_bawah", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.jari_kaki),expression:"pemeriksaan_fisik.jari_kaki"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.jari_kaki,
                                },attrs:{"type":"text","placeholder":"Jari Kaki"},domProps:{"value":(_vm.pemeriksaan_fisik.jari_kaki)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "jari_kaki", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.kuku_kaki),expression:"pemeriksaan_fisik.kuku_kaki"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.kuku_kaki,
                                },attrs:{"type":"text","placeholder":"Kuku Kaki"},domProps:{"value":(_vm.pemeriksaan_fisik.kuku_kaki)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "kuku_kaki", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pemeriksaan_fisik.persendian_kaki),expression:"pemeriksaan_fisik.persendian_kaki"}],staticClass:"form-control",class:{
                                  'is-invalid': _vm.formValidate.persendian_kaki,
                                },attrs:{"type":"text","placeholder":"Persendian Kaki"},domProps:{"value":(_vm.pemeriksaan_fisik.persendian_kaki)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pemeriksaan_fisik, "persendian_kaki", $event.target.value)}}})])])])])])])])],1)])])])],1)])]),_c('div',{staticClass:"wrapper clearfix"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleForm(1)}}},[_vm._v(" Sebelumnya ")]),(!this.loadingData)?_c('button',{staticClass:"btn btn-teal pull-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveData(4)}}},[(this.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" Selanjutnya ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }